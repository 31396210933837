
export const availableTranslations = ["en-GB", "en-US", "fr-FR", "de-DE", "da-DK"];

export const translations = {
    
    "en-GB": { 
        maxCountLineBreaks: "Please note that only 4 lines breaks are possible.",
        headerChangeLangCountry: "Change Country - Language",
        footerContact: "Contact",
        footerPrivacy: "Privacy Policy",
        footerFAQ: "FAQ",
        footerTerms: "Terms & Conditions",
        footerImprint: "Imprint",

        homeHeadlineSteps: "This is how it works", 
        homeStep1: "Get a promotion code for 600 points and design your personal LEGO greeting card.", 
        homeStep2: "Choose a theme and a background for your card and design your LEGO minifigure according to your wishes.", 
        homeStep3: "Receive your personalised LEGO greetings card free of charge by post within 1-2 weeks.", 
        homeThemeHeadline: "Select your favourite LEGO® theme",
        homeThemeMinifigures: "LEGO Minifigures",
        homeThemeCity: "LEGO City (Space)",
        homeThemeNinjago: "LEGO Ninjago",
        homeThemeFriends: "LEGO Friends",

        optionHeadlineFace: "Face",
	    optionHeadlineSkincolor: "Skin colour",
        optionHeadlineOutfitTop: "Outfit",
        optionHeadlineOutfitBottom: "Outfit Bottom",
        optionHeadlineHaircolor: "Wigs and hats",
        optionHeadlineStickers: "Stickers",
        optionHeadlineProps: "Props",
        optionHeadlineShirt: "T-Shirts",
        optionHeadlineMessage: "Name and Message",
        optionHeadlineBackCover: "Back page",
        optionInvalidName: "This name is not allowed. Please change text and click on ,Set' or continue without.",
        optionInvalidBackpageCopy: "This text is not allowed. Please change text and click on ,Set' or continue without.",

        addressComposHeadline: "Great!",
        addressComposCopy1: "Now let's send your greeting card.",
        addressComposCopy2: "Please note that the greeting card will be sent in an envelope.",
        addressComposCopy3_1: "Of course you can still",
        addressComposCopy3_2: "make changes",
        addressComposCopy3_3: ".",
        addressPromoLabel: "Promotion Code*",
        addressPromoPlaceholder: "Please enter 8 digit code here",
        addressPromoError: "Please enter your promotion code.",
        addressPromoHelp: "Where do you want to send the card?",
        addressPromoOption1: "Please send it to myself.",
        addressPromoOption2: "I'd like the LEGO Group to send it to someone else.",

        addressFormHlMyAddr: "Your address",
        addressFormHlReceiverAddr: "Recipient address",
        addressFormLabelFirstname: "First Name",
        addressFormLabelLastname: "Last Name",
        addressFormLabelAppartment: "Apartment",
        addressFormLabelStr: "Address (Street and house number)",
        addressFormLabelCity: "City",
        addressFormLabelZip: "Postal Code",
        addressFormLabelCountry: "Country",
        addressFormLabelComp: "Company",
        addressFormLabelCounty: "State",
        addressFormLabelDistrict: "District",
        addressFormLabelSenderFirstname: "Firstname",
        addressFormLabelSenderLastname: "Lastname",
        addressFormLabelEmail: "Email",
        addressFormLabelTerms: "I have read the <a target='_blank' href='https://www.lego.com/legal/notices-and-policies/privacy-policy'>privacy policy</a>  and consent to the processing of my personal data as described therein. I accept the conditions of <a target='_blank' href='https://www.lego-postcard.com/en-GB/terms'>participation</a>. *",
        addressFormErrFirstname: "First Name must be at least 2 characters.",
        addressFormErrLastname: "Last Name must be at least 2 characters.",
        addressFormErrStr: "Address line is a required field.",
        addressFormErrCity: "City must be at least 3 characters.",
        addressFormErrZip: "Please enter a valid Postal Code",
        addressFormErrCountry: "Enter Country please.",
        addressFormErrCounty: "Enter State please.",
        addressFormErrDistrict: "Enter District please.",
        addressFormErrSenderFirstname: "Enter Firstname please.",
        addressFormErrSenderLastname: "Enter Lastname please.",
        addressFormErrEmail: "Email is required.",
        addressForErrTerms: "You must accept Terms and Conditions.",
        addressFormSubmit: "order now",
        addressOrderFailed: "Create-Order failed",
        addressConnectionFailed: "Connection failed",

        thanksHeadline1: "Thank you",
        thanksCopy1: "Your personalised LEGO greetings card will now be printed and sent free of charge by post to the given address within approximately 1-2 weeks. We will inform you about delivery in a separate email.",
        thanksButton1: "Design another greeting card",
        thanksHeadline2: "Share the greeting card",
        thanksCopy2: "Send your card by email to friends and family.",
        thanksButton2: "Share by email",

        shareHeadline: "You received a greeting card",
        shareCopy: "If you’d like to create a greeting card as well, you can take a look at this in the <a target='_blank' href='https://www.lego.com/Insiders'>Reward Centre</a>",
        flipButton: "flip",
        shareButton: "share",
        shareOrderNotFound: "Your card isn't available. Please contact our customer service:",
        shareFailedButton: "www.lego.com/service",

        finishedHeadline: "Congratulations",
        finishedCopy_1: "You're done.",
        finishedCopy_2: "Of course you can still",
        finishedCopy_3: "make changes",
        finishedCopy_4: "or",
        finishedBtn: "Send greetings card!",

        unsavedHeadline: "Unsaved changes",
        unsavedCopy: "Please confirm your entry by clicking 'set'. ",
        unsavedBtn: "ok",

        baseSelecHeadline: "Select your base",
        baseSelecName1: "Base 1",
        baseSelecName2: "Base 2",
        baseSelecName3: "Base 3",
        baseSelecName4: "Base 4",

        backgroundSwipe: "Swipe to choose background",
        pleaseSelect: "Please select",

        setInput: "set",
        backpagePlaceholder: "Enter your text here. You can also leave it out and add a handwritten message on the postcard you received.",
        messagePlaceholder: "Name",
        invalidChars: "Contains invalid characters...",

        emailSubject: "Your LEGO greetings card",
        emailBody1: "Dear",
        emailBody2: ",",
        emailBody3: "Here is very special LEGO greetings card for yourself or a friend:",
        emailClosing: "Kind regards,"
    },
    "en-US": { 
        maxCountLineBreaks: "Please note that only 4 lines breaks are possible.",
        headerChangeLangCountry: "Change Country - Language",
        footerContact: "Contact",
        footerPrivacy: "Privacy Policy",
        footerFAQ: "FAQ",
        footerTerms: "Terms & Conditions",
        footerImprint: "Imprint",
    
        homeHeadlineSteps: "Here's how it works", 
        homeStep1: "Get a promotion code for 600 points and design your personal LEGO greeting card.", 
        homeStep2: "Choose a theme and a background for your card and design your LEGO minifigure according to your wishes.", 
        homeStep3: "Get your personalized LEGO greeting card for free by mail within 2-3 weeks.", 
        homeThemeHeadline: "Select your favorite LEGO® theme",
        homeThemeMinifigures: "LEGO Minifigures",
        homeThemeCity: "LEGO City (Space)",
        homeThemeNinjago: "LEGO Ninjago",
        homeThemeFriends: "LEGO Friends",
    
        optionHeadlineFace: "Face",
        optionHeadlineSkincolor: "Skin Color",
        optionHeadlineOutfitTop: "Outfit",
        optionHeadlineOutfitBottom: "Outfit Bottom",
        optionHeadlineHaircolor: "Wigs And Hats",
        optionHeadlineStickers: "Stickers",
        optionHeadlineProps: "Props",
        optionHeadlineShirt: "T-Shirts",
        optionHeadlineMessage: "Name and Message",
        optionHeadlineBackCover: "Back Page",
        optionInvalidName: "This name is not allowed. Please change text and click on ,Set' or continue without.",
        optionInvalidBackpageCopy: "This text is not allowed. Please change text and click on ,Set' or continue without.",
    
        addressComposHeadline: "Great!",
        addressComposCopy1: "Now let's send your greeting card.",
        addressComposCopy2: "Please note, the greeting card will be sent in an envelope.",
        addressComposCopy3_1: "Of course you can still",
        addressComposCopy3_2: "make changes",
        addressComposCopy3_3: ".",
        addressPromoLabel: "Promotion Code*",
        addressPromoPlaceholder: "Please enter 8 digit code here",
        addressPromoError: "Please enter your promotion code.",
        addressPromoHelp: "Where do you want to send the card?",
        addressPromoOption1: "Please send it to myself.",
        addressPromoOption2: "I want the LEGO GROUP to send it to someone else.",
    
        addressFormHlMyAddr: "Your address",
        addressFormHlReceiverAddr: "Recipient address",
        addressFormLabelFirstname: "First Name",
        addressFormLabelLastname: "Last Name",
        addressFormLabelAppartment: "Apartment",
        addressFormLabelStr: "Address (Street and house number)",
        addressFormLabelCity: "City",
        addressFormLabelZip: "ZIP Code",
        addressFormLabelCountry: "Country",
        addressFormLabelComp: "Company",
        addressFormLabelCounty: "State",
        addressFormLabelDistrict: "District",
        addressFormLabelSenderFirstname: "Firstname",
        addressFormLabelSenderLastname: "Lastname",
        addressFormLabelEmail: "Email",
        addressFormLabelTerms: "I have read the <a target='_blank' href='https://www.lego.com/legal/notices-and-policies/privacy-policy'>privacy policy</a> and consent to the processing of my personal data as described therein. I accept the conditions of <a target='_blank' href='https://www.lego-postcard.com/en-us/terms'>participation</a>. *",
        addressFormErrFirstname: "First Name must be at least 2 characters.",
        addressFormErrLastname: "Last Name must be at least 2 characters.",
        addressFormErrStr: "Address line is a required field.",
        addressFormErrCity: "City must be at least 3 characters.",
        addressFormErrZip: "Please enter a valid ZIP.",
        addressFormErrCountry: "Enter Country please.",
        addressFormErrCounty: "Enter State please.",
        addressFormErrDistrict: "Enter District please.",
        addressFormErrSenderFirstname: "Enter Firstname please.",
        addressFormErrSenderLastname: "Enter Lastname please.",
        addressFormErrEmail: "Email is required.",
        addressForErrTerms: "You must accept Terms and Conditions.",
        addressFormSubmit: "order now",
        addressOrderFailed: "Create-Order failed",
        addressConnectionFailed: "Connection failed",
    
        thanksHeadline1: "Thank You",
        thanksCopy1: "Your personalized LEGO greeting card will now be printed and sent for free by mail to the given address within about 2-3 weeks. We will inform you about the shipping in a separate email.",
        thanksButton1: "Design Another Greeting Card",
        thanksHeadline2: "Share The Greeting Card",
        thanksCopy2: "Send your card by email to friends and family.",
        thanksButton2: "Share By Email",
    
        shareHeadline: "You Received A Greeting card",
        shareCopy: "If you want to create a greeting card as well, you can check this out on the <a target='_blank' href='https://www.lego.com/Insiders'>Reward Centre</a>",
        flipButton: "flip",
        shareButton: "share",
        shareOrderNotFound: "Your card isn't available. Please contact our customer service:",
        shareFailedButton: "www.lego.com/service",

        finishedHeadline: "Congratulations",
        finishedCopy_1: "You're done.",
        finishedCopy_2: "Of course you can still",
        finishedCopy_3: "make changes",
        finishedCopy_4: "or",
        finishedBtn: "Send Greeting Card!",

        unsavedHeadline: "Unsaved changes",
        unsavedCopy: "Please confirm your entry by clicking 'set'. ",
        unsavedBtn: "ok",

        baseSelecHeadline: "Select Your Base",
        baseSelecName1: "Base 1",
        baseSelecName2: "Base 2",
        baseSelecName3: "Base 3",
        baseSelecName4: "Base 4",

        backgroundSwipe: "Swipe to choose background",
        pleaseSelect: "Please select",
    
        setInput: "set",
        backpagePlaceholder: "Enter your text here. You can also leave it out and add a handwritten message on the greeting card you received.",
        messagePlaceholder: "Name",
        invalidChars: "Contains invalid characters...",

        emailSubject: "Your LEGO greeting card",
        emailBody1: "Hi",
        emailBody2: ",",
        emailBody3: "Here is a very special LEGO greeting card for you or a friend: ",
        emailClosing: "Best regards,"
    },
    "de-DE": { 
        maxCountLineBreaks: "Bitte beachte, dass nur 4 Absätze möglich sind.",
        headerChangeLangCountry: "Land – Sprache ändern",
        footerContact: "Kontakt",
        footerPrivacy: "Datenschutz",
        footerFAQ: "FAQ",
        footerTerms: "Allgemeine Geschäftsbedingungen",
        footerImprint: "Impressum",

        homeHeadlineSteps: "So geht’s", 
        homeStep1: "Hol dir für 600 Punkte einen Aktionscode und gestalte deine ganz persönliche LEGO Grußkarte.", 
        homeStep2: "Wähle eine Themenwelt und einen Hintergrund für deine Karte aus und gestalte deine LEGO Minifigur ganz nach deinen Wünschen.", 
        homeStep3: "Du erhältst deine selbst gestaltete LEGO Grußkarte innerhalb von ein bis zwei Wochen kostenlos per Post.", 
        homeThemeHeadline: "Wähle deinen Favoriten unter den LEGO® Themenwelten",
        homeThemeMinifigures: "LEGO Minifigures",
        homeThemeCity: "LEGO City (Space)",
        homeThemeNinjago: "LEGO Ninjago",
        homeThemeFriends: "LEGO Friends",

        optionHeadlineFace: "Gesicht",
	    optionHeadlineSkincolor: "Hautfarbe",
        optionHeadlineOutfitTop: "Kleidung",
        optionHeadlineOutfitBottom: "Kleidung Unterteil",
        optionHeadlineHaircolor: "Haare und Hüte",
        optionHeadlineStickers: "Sticker",
        optionHeadlineProps: "Zubehör",
        optionHeadlineShirt: "Kleidung Oberteil",
        optionHeadlineMessage: "Name und Nachricht",
        optionHeadlineBackCover: "Rückseite",
        optionInvalidName: "Dieser Name ist nicht erlaubt. Bitte ändere deinen Text und klicke auf „Fertig“ oder fahre ohne Name fort.",
        optionInvalidBackpageCopy: "Dieser Text ist nicht erlaubt. Bitte ändere deinen Text und klicke auf „Fertig“ oder fahre ohne Text fort.",

        addressComposHeadline: "Super!",
        addressComposCopy1: "Als Nächstes versenden wir deine Grußkarte.",
        addressComposCopy2: "Bitte beachte, dass die Grußkarte in einem Umschlag versendet wird.",
        addressComposCopy3_1: "Du kannst sie natürlich immer noch",
        addressComposCopy3_2: "bearbeiten",
        addressComposCopy3_3: ".",
        addressPromoLabel: "Aktionscode*",
        addressPromoPlaceholder: "Bitte gib hier den 8-stelligen Code ein",
        addressPromoError: "Bitte gib deinen Aktionscode an.",
        addressPromoHelp: "An wen möchtest du die Karte senden?",
        addressPromoOption1: "Bitte sendet sie an mich selbst.",
        addressPromoOption2: "Ich möchte, dass die LEGO Gruppe sie an eine andere Person sendet.",

        addressFormHlMyAddr: "Deine Adresse",
        addressFormHlReceiverAddr: "Empfängeradresse",
        addressFormLabelFirstname: "Vorname",
        addressFormLabelLastname: "Nachname",
        addressFormLabelAppartment: "Wohnungs-/Zimmernummer",
        addressFormLabelStr: "Anschrift (Straße und Hausnummer)",
        addressFormLabelCity: "Ort",
        addressFormLabelZip: "Postleitzahl",
        addressFormLabelCountry: "Land",
        addressFormLabelComp: "Unternehmen",
        addressFormLabelCounty: "Kreis",
        addressFormLabelDistrict: "Bundesland",
        addressFormLabelSenderFirstname: "Vorname",
        addressFormLabelSenderLastname: "Nachname",
        addressFormLabelEmail: "E-Mail",
        addressFormLabelTerms: "Ich habe die <a target='_blank' href='https://www.lego.com/legal/notices-and-policies/privacy-policy'>Datenschutzrichtlinien</a>  gelesen und stimme der Verarbeitung meiner personenbezogenen Daten wie hierin beschrieben zu. Ich akzeptiere die <a target='_blank' href='https://www.lego-postcard.com/de-DE/terms'>Allgemeinen Geschäftsbedingungen für die Teilnahme</a>. *",
        addressFormErrFirstname: "Der Vorname muss mindestens 2 Zeichen lang sein.",
        addressFormErrLastname: "Der Nachname muss mindestens 2 Zeichen lang sein.",
        addressFormErrStr: "Das Feld „Adresszeile“ muss ausgefüllt werden.",
        addressFormErrCity: "Die Ortsbezeichnung muss mindestens 3 Zeichen lang sein.",
        addressFormErrZip: "Bitte gib eine gültige Postleitzahl ein.",
        addressFormErrCountry: "Bitte gib das Land ein.",
        addressFormErrCounty: "Bitte gib den Kreis ein.",
        addressFormErrDistrict: "Bitte gib das Bundesland ein.",
        addressFormErrSenderFirstname: "Bitte gib den Vornamen ein.",
        addressFormErrSenderLastname: "Bitte gib den Nachnamen ein.",
        addressFormErrEmail: "Die E-Mail-Adresse muss angegeben werden.",
        addressForErrTerms: "Du musst die Allgemeinen Geschäftsbedingungen akzeptieren.",
        addressFormSubmit: "Jetzt bestellen",
        addressOrderFailed: "Bestellung fehlgeschlagen",
        addressConnectionFailed: "Verbindung fehlgeschlagen",

        thanksHeadline1: "Vielen Dank",
        thanksCopy1: "Deine selbst gestaltete LEGO Grußkarte wird jetzt gedruckt und innerhalb von etwa ein bis zwei Wochen kostenlos per Post an die angegebene Adresse gesendet. Wir informieren dich per E-Mail, wenn die Karte versendet wurde.",
        thanksButton1: "Eine weitere Grußkarte gestalten",
        thanksHeadline2: "Die Grußkarte teilen",
        thanksCopy2: "Sende deine Karte per E-Mail an Freunde und Familie.",
        thanksButton2: "Per E-Mail teilen",

        shareHeadline: "Du hast eine Grußkarte erhalten",
        shareCopy: "Wenn du auch eine Grußkarte gestalten möchtest, schau im <a target='_blank' href='https://www.lego.com/Insiders'>Prämien-Center</a> vorbei.",
        flipButton: "Drehen",
        shareButton: "Teilen",
        shareOrderNotFound: "Deine Karte ist nicht verfügbar. Bitte wende dich an unseren Kundenservice:",
        shareFailedButton: "www.lego.com/service",

        finishedHeadline: "Glückwunsch",
        finishedCopy_1: "Du bist fertig.",
        finishedCopy_2: "Du kannst sie natürlich immer noch",
        finishedCopy_3: "bearbeiten",
        finishedCopy_4: "oder die",
        finishedBtn: "Karte absenden!",

        unsavedHeadline: "Ungespeicherte Änderungen",
        unsavedCopy: "Bitte bestätige deine Eingabe, indem du auf „Fertig“ klickst. ",
        unsavedBtn: "OK",

        baseSelecHeadline: "Wähle deine Figur",
        baseSelecName1: "Figur 1",
        baseSelecName2: "Figur 2",
        baseSelecName3: "Figur 3",
        baseSelecName4: "Figur 4",

        backgroundSwipe: "Wische, um den Hintergrund zu wählen",
        pleaseSelect: "Bitte auswählen",

        setInput: "Fertig",
        backpagePlaceholder: "Gib hier deinen Text ein. Du kannst dies überspringen und der Grußkarte eine handgeschriebene Nachricht hinzufügen, wenn du sie erhalten hast.",
        messagePlaceholder: "Name",
        invalidChars: "Enthält ungültige Zeichen …",

        emailSubject: "Deine LEGO Grußkarte",
        emailBody1: "Hallo",
        emailBody2: ",",
        emailBody3: "hier ist eine ganz besondere LEGO Grußkarte für dich selbst, einen Freund oder eine Freundin: ",
        emailClosing: "Viele Grüße,"
    },
    "fr-FR": { 
        maxCountLineBreaks: "Veuillez noter que seuls 4 sauts de ligne sont possibles.",
        headerChangeLangCountry: "Modifier le pays / la langue",
        footerContact: "Contact",
        footerPrivacy: "Politique relative à la vie privée",
        footerFAQ: "FAQ",
        footerTerms: "Conditions générales",
        footerImprint: "Impression",
    
        homeHeadlineSteps: "Voilà comment procéder", 
        homeStep1: "Reçois un code de promotion en échangeant 600 points et crée ta carte de vœux LEGO personnelle.", 
        homeStep2: "Choisis un thème et un arrière-plan pour ta carte et personnalise ta figurine LEGO comme tu le souhaites.", 
        homeStep3: "Reçois ta carte de vœux LEGO personnalisée gratuitement par courrier sous environ 1-2 semaines.", 
        homeThemeHeadline: "Choisis ton thème LEGO® préféré",
        homeThemeMinifigures: "LEGO Minifigures",
        homeThemeCity: "LEGO City (Space)",
        homeThemeNinjago: "LEGO Ninjago",
        homeThemeFriends: "LEGO Friends",
    
        optionHeadlineFace: "Visage",
        optionHeadlineSkincolor: "Couleur de la peau",
        optionHeadlineOutfitTop: "Tenue",
        optionHeadlineOutfitBottom: "Bas de la tenue",
        optionHeadlineHaircolor: "Cheveux et couvre-chef",
        optionHeadlineStickers: "Stickers",
        optionHeadlineProps: "Accessoires",
        optionHeadlineShirt: "T-Shirts",
        optionHeadlineMessage: "Nom et message",
        optionHeadlineBackCover: "Verso",
        optionInvalidName: "Ce nom n'est pas autorisé. Merci de modifier le texte et de cliquer sur «Définir» ou continuer sans.",
        optionInvalidBackpageCopy: "Ce texte n'est pas autorisé. Merci de modifier le texte et de cliquer sur «Définir» ou continuer sans.",
    
        addressComposHeadline: "Parfait !",
        addressComposCopy1: "Envoyons maintenant ta carte de vœux.",
        addressComposCopy2: "Elle te sera envoyée dans une enveloppe.",
        addressComposCopy3_1: "Tu peux toujours bien sûr",
        addressComposCopy3_2: "faire des changements",
        addressComposCopy3_3: ".",
        addressPromoLabel: "Code de promotion*",
        addressPromoPlaceholder: "Merci de saisir un code à 8 chiffres ici",
        addressPromoError: "Merci de saisir ton code de promotion.",
        addressPromoHelp: "Où veux-tu envoyer la carte ?",
        addressPromoOption1: "Merci de me l'envoyer.",
        addressPromoOption2: "J'aimerais que le Groupe LEGO l'envoie à une autre personne.",
    
        addressFormHlMyAddr: "Ton adresse",
        addressFormHlReceiverAddr: "Adresse du destinataire",
        addressFormLabelFirstname: "Prénom",
        addressFormLabelLastname: "Nom de famille",
        addressFormLabelAppartment: "Appartement",
        addressFormLabelStr: "Adresse (rue et numéro de maison)",
        addressFormLabelCity: "Ville",
        addressFormLabelZip: "Code postal",
        addressFormLabelCountry: "Pays",
        addressFormLabelComp: "Entreprise",
        addressFormLabelCounty: "Département",
        addressFormLabelDistrict: "État / région",
        addressFormLabelSenderFirstname: "Prénom",
        addressFormLabelSenderLastname: "Nom de famille",
        addressFormLabelEmail: "Adresse e-mail",
        addressFormLabelTerms: "J'ai lu la <a target='_blank' href='https://www.lego.com/legal/notices-and-policies/privacy-policy'>Politique relative à la vie privée</a> et je consens au traitement de mes données personnelles comme stipulé dans le présent document. J'accepte le <a target='_blank' href='https://www.lego-postcard.com/fr-FR/terms'>conditions générales de participation</a>. *",
        addressFormErrFirstname: "Le prénom doit comporter au moins 2 caractères.",
        addressFormErrLastname: "Le nom de famille doit comporter au moins 2 caractères.",
        addressFormErrStr: "Le champ Adresse est obligatoire.",
        addressFormErrCity: "La ville doit comporter au moins 3 caractères.",
        addressFormErrZip: "Merci d'entrer un code postal valide.",
        addressFormErrCountry: "Merci d'entrer un pays.",
        addressFormErrCounty: "Merci d'entrer un département.",
        addressFormErrDistrict: "Merci d'entrer un état / une région.",
        addressFormErrSenderFirstname: "Merci d'entrer un prénom.",
        addressFormErrSenderLastname: "Merci d'entrer un nom de famille.",
        addressFormErrEmail: "L'adresse e-mail est requise",
        addressForErrTerms: "Tu dois accepter les conditions générales.",
        addressFormSubmit: "Commander maintenant",
        addressOrderFailed: "Échec de la commande",
        addressConnectionFailed: "Échec de connexion",
    
        thanksHeadline1: "Merci",
        thanksCopy1: "Ta carte de vœux LEGO personnalisée sera maintenant imprimée et envoyée gratuitement par la poste à l'adresse indiquée sous environ 1-2 semaines. Nous t'informerons dans un autre e-mail de son envoi.",
        thanksButton1: "Créer une autre carte de vœux",
        thanksHeadline2: "Partager la carte de vœux",
        thanksCopy2: "Envoie ta carte par e-mail à tes amis et ta famille.",
        thanksButton2: "Partager par email",
    
        shareHeadline: "Tu as reçu une carte de vœux",
        shareCopy: "Si tu souhaites également créer une carte de vœux, tu peux regarder les modèles dans le <a target='_blank' href='https://www.lego.com/Insiders'>centre des récompenses</a>",
        flipButton: "Feuilleter",
        shareButton: "Partager",
        shareOrderNotFound: "Ta carte n'est pas disponible. Merci de contacter notre service clientèle :",
        shareFailedButton: "www.lego.com/service",

        finishedHeadline: "Félicitations",
        finishedCopy_1: "Tu as terminé.",
        finishedCopy_2: "Bien sûr, tu peux toujours",
        finishedCopy_3: "faire des changements",
        finishedCopy_4: "ou",
        finishedBtn: "Envoyer la carte de vœux !",

        unsavedHeadline: "Modifications non enregistrées",
        unsavedCopy: "Merci de confirmer ta saisie en cliquant sur «Définir». ",
        unsavedBtn: "ok",

        baseSelecHeadline: "Sélectionne ta base",
        baseSelecName1: "Base 1",
        baseSelecName2: "Base 2",
        baseSelecName3: "Base 3",
        baseSelecName4: "Base 4",

        backgroundSwipe: "Fais glisser pour choisir l'arrière-plan",
        pleaseSelect: "Fais ton choix",
    
        setInput: "Définir",
        backpagePlaceholder: "Saisis ton texte ici. Tu peux aussi laisser le champ vide et ajouter un message à la main sur la carte de vœux que tu as reçue.",
        messagePlaceholder: "Nom",
        invalidChars: "Contient des caractères non valides...",

        emailSubject: "Ta carte de vœux LEGO",
        emailBody1: "Cher/chère",
        emailBody2: "",
        emailBody3: "Voici une carte de vœux LEGO très spéciale pour toi ou un(e) ami(e) : ",
        emailClosing: "Amitiés"
    },
    "da-DK": { 
        maxCountLineBreaks: "Bemærk, at der kun er mulighed for 4 linjeskift.",
        headerChangeLangCountry: "Skift land – sprog",
        footerContact: "Kontakt",
        footerPrivacy: "Politik om beskyttelse af personlige oplysninger",
        footerFAQ: "Ofte stillede spørgsmål",
        footerTerms: "Vilkår og betingelsers",
        footerImprint: "Imprint",
    
        homeHeadlineSteps: "Sådan fungerer det", 
        homeStep1: "Få en kampagnekode for 600 point, og design dit eget personlige LEGO-brevkort.", 
        homeStep2: "Vælg et tema og en baggrund til dit kort, og design din LEGO-minifigur, som du vil", 
        homeStep3: "Modtag dit personlige LEGO-brevkort gratis med posten inden for 2 uger.", 
        homeThemeHeadline: "Vælg dit foretrukne LEGO® tema",
        homeThemeMinifigures: "LEGO Minifigures",
        homeThemeCity: "LEGO City (Space)",
        homeThemeNinjago: "LEGO Ninjago",
        homeThemeFriends: "LEGO Friends",
    
        optionHeadlineFace: "Ansigt",
        optionHeadlineSkincolor: "Hudfarve",
        optionHeadlineOutfitTop: "Tøj",
        optionHeadlineOutfitBottom: "Tøj – underdel",
        optionHeadlineHaircolor: "Hår og hatte",
        optionHeadlineStickers: "Klistermærker",
        optionHeadlineProps: "Tilbehør",
        optionHeadlineShirt: "T-Shirts",
        optionHeadlineMessage: "Navn og besked",
        optionHeadlineBackCover: "Bagside",
        optionInvalidName: "Dette navn er ikke tilladt. Rediger teksten, og klik på „Gem“ eller fortsæt uden.",
        optionInvalidBackpageCopy: "Denne tekst er ikke tilladt. Rediger teksten, og klik på „Gem“ eller fortsæt uden.",
    
        addressComposHeadline: "Fantastisk!",
        addressComposCopy1: "Lad os nu sende dit brevkort afsted.",
        addressComposCopy2: "Bemærk, at revkortet sendes i en konvolut.",
        addressComposCopy3_1: "Du kan selvfølgelig stadig",
        addressComposCopy3_2: "foretage ændringer",
        addressComposCopy3_3: ".",
        addressPromoLabel: "Kampagnekode*",
        addressPromoPlaceholder: "Indtast den 8-cifrede kode her",
        addressPromoError: "Indtast din kampagnekode.",
        addressPromoHelp: "Hvor vil du sende kortet hen?",
        addressPromoOption1: "Send det til mig selv.",
        addressPromoOption2: "Jeg vil gerne have LEGO Gruppen til at sende kortet til en anden.",
    
        addressFormHlMyAddr: "Din adresse",
        addressFormHlReceiverAddr: "Modtagers adresse",
        addressFormLabelFirstname: "Fornavn",
        addressFormLabelLastname: "Efternavn",
        addressFormLabelAppartment: "Lejlighed",
        addressFormLabelStr: "Adresse (gade og husnummer)",
        addressFormLabelCity: "By",
        addressFormLabelZip: "Postnummer",
        addressFormLabelCountry: "Land",
        addressFormLabelComp: "Firma",
        addressFormLabelCounty: "Region",
        addressFormLabelDistrict: "Stat",
        addressFormLabelSenderFirstname: "Fornavn",
        addressFormLabelSenderLastname: "Efternavn",
        addressFormLabelEmail: "E-mailadresse",
        addressFormLabelTerms: "Jeg har læst <a target='_blank' href='https://www.lego.com/legal/notices-and-policies/privacy-policy'>Politik om beskyttelse af personlige oplysninger</a> og accepterer, at mine personlige oplysninger må behandles som beskrevet heri. Jeg accepterer <a target='_blank' href='https://www.lego-postcard.com/da-DK/terms'>Vilkår og betingelse for deltagelse</a>. *",
        addressFormErrFirstname: "Fornavn skal indeholde mindst 2 tegn.",
        addressFormErrLastname: "Efternavn skal indeholde mindst 2 tegn.",
        addressFormErrStr: "Du skal angive en adresse.",
        addressFormErrCity: "By skal indeholde mindst 3 tegn.",
        addressFormErrZip: "Indtast et gyldigt postnummer.",
        addressFormErrCountry: "Indtast et land.",
        addressFormErrCounty: "Indtast en region.",
        addressFormErrDistrict: "Indtast en stat.",
        addressFormErrSenderFirstname: "Indtast et fornavn.",
        addressFormErrSenderLastname: "Indtast et efternavn.",
        addressFormErrEmail: "Du skal angive en e-mailadresse.",
        addressForErrTerms: "Du skal acceptere Vilkår og betingelser.",
        addressFormSubmit: "Bestil nu",
        addressOrderFailed: "Bestillingen mislykkedes",
        addressConnectionFailed: "Der kunne ikke oprettes forbindelse",
    
        thanksHeadline1: "Tak",
        thanksCopy1: "Dit personlige LEGO-brevkort bliver nu trykt og sendt gratis med posten til den angivne adresse inden for cirka 2 uger. Vi sender dig en separat e-mail, når kortet er afsendt.",
        thanksButton1: "Design et nyt brevkort",
        thanksHeadline2: "Del brevkortet",
        thanksCopy2: "Send dit kort til venner og familie via e-mail.",
        thanksButton2: "Del via e-mail",
    
        shareHeadline: "Du har modtaget et brevkort",
        shareCopy: "Hvis du også gerne vil lave et brevkort, kan du finde det i <a target='_blank' href='https://www.lego.com/Insiders'>bonuscenteret</a>",
        flipButton: "Vend",
        shareButton: "Del",
        shareOrderNotFound: "Dit kort er ikke tilgængeligt. Kontakt vores kundeservice:",
        shareFailedButton: "www.lego.com/service",

        finishedHeadline: "Tillykke!",
        finishedCopy_1: "Nu er du færdig.",
        finishedCopy_2: "Du kan stadig",
        finishedCopy_3: "foretage ændringer",
        finishedCopy_4: " hvis du ønsker det, eller du kan vælge",
        finishedBtn: "Send brevkort!!",

        unsavedHeadline: "Ikke-gemte ændringer",
        unsavedCopy: "Bekræft teksten ved at klikke på „Gem“. ",
        unsavedBtn: "OK",

        baseSelecHeadline: "Vælg en basisfigur",
        baseSelecName1: "Basisfigur 1",
        baseSelecName2: "Basisfigur 2",
        baseSelecName3: "Basisfigur 3",
        baseSelecName4: "Basisfigur 4",

        backgroundSwipe: "Swipe for at vælge en baggrund",
        pleaseSelect: "Vælg",
    
        setInput: "Gem",
        backpagePlaceholder: "Indtast din tekst her. Du kan også lade feltet stå tomt og tilføje en håndskrevet besked på brevkortet, når du modtager det.",
        messagePlaceholder: "Navn",
        invalidChars: "Indeholder ugyldige tegn ...",

        emailSubject: "Dit LEGO-brevkort",
        emailBody1: "Kære",
        emailBody2: "",
        emailBody3: "her er et helt særligt LEGO-brevkort til dig selv eller en ven: ",
        emailClosing: "Venlig hilsen"
    },
}