import React from 'react'
import { connect } from 'react-redux';
import FlipCard from '../components/FlipCard';
import Header from '../components/Header';
import { getEnvConfig } from '../env';
import axios from 'axios';

class Thankyou extends React.Component {

    componentDidMount = () => {
        this.props.dispathUpdateInternalLocale();

        //tracking
        this.env = getEnvConfig();
        axios.post(this.env.apibase + 'tracking.php?cv='+Date.now(), { url: window.location.href }).then(res => {}).catch(e=>{})
    }
    render = () => {

        const shareUrl = window.location.origin + "/" + this.props.locale + "/share/"+this.props.match.params.orderid;
        return (
            <>
                <Header />
                <div className="deskFrame thankyou">

                    <div className="deskFrame-wrap Grid">
                        <div className="deskFrame-bgBlue">
                            <h2>{ this.props.t.t("thanksHeadline1") }</h2>
                            <p>{ this.props.t.t("thanksCopy1") }</p>
                            <img decoding="async" className="u-marginTopMd u-marginBottomMd thankyou-anotherCard" src="../images/ThankYouBatmann.png" alt="" />
                            <a href={ "/"+this.props.locale+"/" } className="Button--orange">{ this.props.t.t("thanksButton1") }</a>                        
                        </div>
                        <div>
                            <h2>{ this.props.t.t("thanksHeadline2") }</h2>
                            <p>{ this.props.t.t("thanksCopy2") }</p>

                            <FlipCard orderId={ this.props.match.params.orderid } />
                            
                            { (this.props.match.params.receiver === "someoneelse" && this.props.match.params.senderFirstname) ?
                                // eslint-disable-next-line
                                <a href={"mailto:?subject="+this.props.t.t("emailSubject")+"&body="+ encodeURI(this.props.t.t("emailBody1") + " " +this.props.match.params.firstname + this.props.t.t("emailBody2") + "\n\n"+this.props.t.t("emailBody3")+"\n"+shareUrl+"\n\n"+this.props.t.t("emailClosing")+"\n" + this.props.match.params.senderFirstname )} className="Button--default">{ this.props.t.t("thanksButton2") }</a>
                                :
                                // eslint-disable-next-line
                                <a href={"mailto:?subject="+this.props.t.t("emailSubject")+"&body="+ encodeURI(this.props.t.t("emailBody1") + " " +this.props.match.params.firstname + this.props.t.t("emailBody2") + "\n\n"+this.props.t.t("emailBody3")+"\n"+shareUrl)  } className="Button--default">{ this.props.t.t("thanksButton2") }</a>
                            }
                        </div>
                    </div>

                </div>
            </>
        )
    }

}
    
    
const mapStateToProp = state => ({
    t: state.t,
    locale: state.locale,
});

const mapDispatchToProps = dispatch => {
    return {
        dispathUpdateInternalLocale : () => dispatch({ type: "UPDATE_LOCALE" }),
    };
}
export default connect(mapStateToProp, mapDispatchToProps)(Thankyou);    