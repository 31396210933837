import React from 'react'
import { Link } from 'react-router-dom'
import { getEnvConfig } from '../env';
import axios from 'axios';

class Error extends React.Component {
    env=null;
    
    componentDidMount = () => {
        //tracking
        this.env = getEnvConfig();
        axios.post(this.env.apibase + 'tracking.php?cv='+Date.now(), { url: window.location.href }).then(res => {}).catch(e=>{})
    }
    render = () => {
        return (
            <div className="contentPadding limitContainer u-textCenter u-marginBottomLg ">
                <h1 className="u-textCenter u-marginBottomLg u-marginTopLg">Sorry, an error occured.</h1>
                <Link to="/" className="Button--default">Home</Link>
            </div>
        )
    }

}
    
export default Error;
    