import React from 'react'
import { connect } from 'react-redux';
import FlipCard from '../components/FlipCard';
import Header from '../components/Header';
import { getEnvConfig } from '../env';
import axios from 'axios';

class Share extends React.Component {

    env=null;

    componentDidMount = () => {
        this.props.dispathUpdateInternalLocale();

        //tracking
        this.env = getEnvConfig();
        axios.post(this.env.apibase + 'tracking.php?cv='+Date.now(), { url: window.location.href }).then(res => {}).catch(e=>{})
    }

    render = () => {
        return (
            <>
                <Header />
                <div className="deskFrame share">
                    <div className="deskFrame-wrap Grid">
                        <div>
                            <h2 className="u-textCenter">{ this.props.t.t("shareHeadline") }</h2>
                            <p className="u-textCenter" dangerouslySetInnerHTML={{ __html: this.props.t.t("shareCopy")}} />
                        
                            <FlipCard orderId={ this.props.match.params.orderid } />
                            
                        </div>
                    </div>

                </div>
            </>
        )
    }

}
    
    
const mapStateToProp = state => ({
    t: state.t,

});

const mapDispatchToProps = dispatch => {
    return {
        dispathUpdateInternalLocale : () => dispatch({ type: "UPDATE_LOCALE" }),
    };
}
export default connect(mapStateToProp, mapDispatchToProps)(Share);    
    
    

