import { availableTranslations } from './translations';

export const helper_getBrowserLocale = () => {

    // get lang from browser
    const userLang = (navigator.language || navigator.userLanguage);
    if(availableTranslations.includes(userLang)){
        return userLang;
    } 
    return "en-GB";
    
}

export const helper_getAllIndices = (str, needle) => {
    var indices = [];
    for(var i=0; i<str.length;i++) {
        if (str[i] === needle) indices.push(i);
    }
    return indices;

}