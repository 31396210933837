import React from 'react';
import { BACKCOVER, MESSAGE, MAX_COUNT_LINEBREAKS } from '../config';

const CompositionItem = (props) => {
    var dynImgPath = props.composition[props.catItem.itemKey];

    if (props.catItem.itemKey === MESSAGE){

        const completeImgPath = "../images/composition/" + props.configurator.themeName + "/" + props.catItem.basePath  + props.locale + "_" + props.composition[MESSAGE] + ".png";
        const text = props.showTextPlaceholders ? (props.composition.messageName || props.t.t("messagePlaceholder") ) : props.composition.messageName;
        return  (
            <div key={ "compImg" + props.catItem.itemKey } className="composition-message">
                <img decoding="async" src={ completeImgPath } alt=""  onError={()=>{ console.error(completeImgPath + " not found") }} />
                <svg className="composition-message-svg" viewBox="0 0 200 45">
                    <text textAnchor="middle" x="50%" y="50%" dominantBaseline="middle" fontFamily="Dosis" fontSize="20px">{ text }</text>
                </svg>
            </div>
        ); 
    } else if (props.catItem.itemKey === BACKCOVER){
        
        const completeImgPath = "../images/composition/" + props.configurator.themeName + "/" + props.catItem.basePath + props.locale + "_" + props.composition.backcover + ".png";
        const text = props.showTextPlaceholders ? (props.composition.backcoverName || props.t.t("backpagePlaceholder") ) : props.composition.backcoverName;
        // console.log(text)
        return  (
            <div key={ "compImg" + props.catItem.itemKey } className={ props.currentSlide === props.index ? "composition-backcover is-active" : "composition-backcover"}>
                <img decoding="async" src={ completeImgPath } alt="" className="composition-backcoverBG"  onError={()=>{ console.error(completeImgPath + " not found") }} />
                <div className="composition-backcover-text">
                    { (text || "").split(/\r?\n|\r/g).map((item, key) => {
                        return <span key={key}>{item}{ key+1 < MAX_COUNT_LINEBREAKS ? <br/> : " "}</span>
                    })}
                </div>
            </div>
        ); 

    } else {

        if (props.catItem.combinedKeyMapping){

            var genMappingKey = props.catItem.combinedKeyMapping;
            for (var i = 0; i < props.catItem.components.length; i++){
                const compoKey = props.catItem.components[i].key;
                genMappingKey = genMappingKey.replace("["+compoKey+"]", props.composition[compoKey]);
            }
            dynImgPath = genMappingKey;

            // special handling for hat
            const hatPos = dynImgPath.indexOf("_hat_");
            if (hatPos > 0 ){
                dynImgPath = dynImgPath.substr(hatPos+1);
            }

        } 

        const completeImgPath = "../images/composition/" + props.configurator.themeName + "/" + props.catItem.basePath  + dynImgPath + ".png";

        return  <div key={ "compImg" + props.catItem.itemKey }><img decoding="async" src={ completeImgPath } alt="" onError={()=>{ console.error(completeImgPath + " not found")}} /></div>; 

    }
}

export default CompositionItem;