import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


class Finished extends React.Component {
    
    
    render = () => {
        return (
            <div className="overlay overlay-finished Grid Grid--withGutter u-flexAlignItemsCenter u-flexJustifyCenter">
                <div className="u-size1of1 u-textCenter">
                    <h2>{ this.props.t.t("finishedHeadline") }</h2>
                    <p>
                        { this.props.t.t("finishedCopy_1") }
                    </p>
                    <img decoding="async" className="overlay-finished-img" src="../images/CongratsUnicorn.png" alt="" />
                    <p>
                        { this.props.t.t("finishedCopy_2")} <span onClick={ this.props.hideOverlay }>{ this.props.t.t("finishedCopy_3") }</span> { this.props.t.t("finishedCopy_4") }
                    </p>
                    <Link to={ "/"+this.props.locale + "/address" } className="Button--orange">{ this.props.t.t("finishedBtn") }</Link>
                </div>
            </div>
        )
    }
}
    


    
const mapStateToProp = state => ({
    composition: state.composition,
    locale: state.locale,
});

const mapDispatchToProps = dispatch => {
    return {
    };
}
export default connect(mapStateToProp, mapDispatchToProps)(Finished);    