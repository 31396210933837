import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Header extends React.Component {
    render = () => {

        return (
            <header className="limitcontent">
                    <div className="Grid Grid--withGutter u-flexAlignItemsCenter">
                        <div className="u-sizeFill u-textLeft">
                            { this.props.showBackTheme &&
                                <Link to=".." className="Button--nostyle">
                                    <img decoding="async" src="./images/icon-arrow-left.svg" alt="icon" />
                                    Themes
                                </Link>
                            }
                        </div>
                        <a href="https://lego.com" className="u-sizeFit u-textCenter">
                            <img decoding="async" src="./images/Lego-logo.jpg" alt="LEGO" />
                        </a>
                        <div className="u-sizeFill u-textRight">
                            <label className="formElement">
                                <span className="formElement-label">{ this.props.t.t("headerChangeLangCountry") }</span>
                                <select className="formElement-input" onChange={ e => { window.location.pathname = e.target.value } } value={ "/"+ this.props.locale + "/"  }>
                                    <option value="/en-GB/">UK - EN</option>
                                    <option value="/en-US/">EN - US</option>
                                    <option value="/da-DK/">DA - DK</option>
                                    <option value="/de-DE/">DE - DE</option>
                                    <option value="/fr-FR/">FR - FR</option>
                                </select>
                            </label>
                        </div>
                    </div>
                </header>
    );
}
}


    
const mapStateToProp = state => ({
    t : state.t,
    locale: state.locale,
});

export default connect(mapStateToProp)(Header);    





