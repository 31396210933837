import React from 'react'
import { connect } from 'react-redux';
import Slider from "react-slick";
import { BACKCOVER } from '../config';

class SliderNavigation extends React.Component {

    render = () => {

        const settingSliderNav = {
            dots: false,
            speed: 500,
            cssEase: 'ease-in-out',
            infinite: false,
            slidesToShow: this.props.configurator.structure.length +1,
            slidesToScroll: 1,
            arrow: false,
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        arrows: true,
                        slidesToScroll: 3,
                        slidesToShow: 6,
                  }
                },
            ]
        };
        
        return (
            <div className=" u-sm-size1of1 slidernavi">
                <Slider { ...settingSliderNav } ref={c => (this.sliderNav = c)} >


                    { this.props.theme.map( (item, index) => {

                        let classes = [  ];
                        if(this.props.currentSlide === index){
                            classes.push("is-active")
                        }
                        if (this.props.finishedCats.indexOf(item.itemKey) > -1 ){
                            classes.push("is-done");
                        }


                        //skip items where only one option is available, but not the backcover
                        if( ( item.components !== undefined && item.components[0].options.length > 1) || item.itemKey === BACKCOVER){
                            return (
                                <div key={ "navi" + item.itemKey } className={ classes.join(" ") } data-cat={ item.itemKey } onClick={ () => this.props.sliderGoTo(index, item.itemKey) }>
                                    <img decoding="async" width="100" height="100" src={ item.catImg } alt="" />
                                    
                                    {/* hide slider controls on composition, if backpage is visible */}
                                    { (this.props.currentSlide === index && item.itemKey === BACKCOVER )&& 
                                    <style>.composition .slick-next, .composition .slick-prev, .composition .slick-dots {"{"} opacity: 0 {"}"}</style>
                                    }
                                </div>
                            )
                        } else {
                            return null;
                        }
                    })}

                    <div onClick={ this.props.showFinishedOverlay } >
                        <img decoding="async" width="100" height="100" src="../images/icon-check.png" alt="" />
                    </div>

                </Slider>
            </div>
        )
    }
}
    


    
const mapStateToProp = state => ({
    finishedCats: state.finishedCats,
    configurator: state.configurator,

});

const mapDispatchToProps = dispatch => {
    return {
    };
}
export default connect(mapStateToProp, mapDispatchToProps)(SliderNavigation);    