import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";


class BaseSelection extends React.Component {

    state={
        selected: 0,
    }

    render = () => {

        let settings = {
            arrows: true,
            centerMode: true,
            centerPadding: '33%',
            slidesToShow:1,
            initialSlide: 0,
            afterChange: current => this.setState({ selected : current%4 }),
        };
        
    
        return (
            <div className="overlay overlay-base Grid Grid--withGutter u-flexAlignItemsCenter u-flexJustifyCenter">
                <div className="u-size1of1 u-textCenter home-limitWidth home-limitWidthBase">
                    <img decoding="async" src="../images/LogoFriends.png" alt="Friends" className="overlay-logo" />
                    <h2>{ this.props.t.t("baseSelecHeadline")}</h2>
                    <Slider {...settings} ref={c => (this.slider = c)} className="slickFrame">
                        <img decoding="async" src="/images/base_selection_0001.png" onClick={ () => this.slider.slickGoTo(0) } alt="Frame" />
                        <img decoding="async" src="/images/base_selection_0002.png" onClick={ () => this.slider.slickGoTo(1) } alt="Frame" />
                        <img decoding="async" src="/images/base_selection_0003.png" onClick={ () => this.slider.slickGoTo(2) } alt="Frame" />
                        <img decoding="async" src="/images/base_selection_0004.png" onClick={ () => this.slider.slickGoTo(3) } alt="Frame" />
                        <img decoding="async" src="/images/base_selection_0001.png" onClick={ () => this.slider.slickGoTo(0) } alt="Frame" />
                        <img decoding="async" src="/images/base_selection_0002.png" onClick={ () => this.slider.slickGoTo(1) } alt="Frame" />
                        <img decoding="async" src="/images/base_selection_0003.png" onClick={ () => this.slider.slickGoTo(2) } alt="Frame" />
                        <img decoding="async" src="/images/base_selection_0004.png" onClick={ () => this.slider.slickGoTo(3) } alt="Frame" />
                    </Slider>

                    { this.state.selected === 0 &&
                        <Link to="create/friends1" onClick={ this.props.hideOverlay } className="Button--default">{ this.props.t.t("baseSelecName1")}</Link>
                    }
                    { this.state.selected === 1 &&
                        <Link to="create/friends2" onClick={ this.props.hideOverlay } className="Button--default">{ this.props.t.t("baseSelecName2")}</Link>
                    }
                    { this.state.selected === 2 &&
                        <Link to="create/friends3" onClick={ this.props.hideOverlay } className="Button--default">{ this.props.t.t("baseSelecName3")}</Link>
                    }
                    { this.state.selected === 3 &&
                        <Link to="create/friends4" onClick={ this.props.hideOverlay } className="Button--default">{ this.props.t.t("baseSelecName4")}</Link>
                    }
                    <img decoding="async" src="/images/icon-close.svg" className="overlay-close" onClick={ this.props.hideOverlay } alt="" />
                </div>
            </div>
        )

    }
}

export default BaseSelection;
    

