export const BACKCOVER = "backcover";
export const MESSAGE = "message";
export const MAX_CHAR_NAME = 20;
export const MAX_CHAR_MSG = 125;
export const MAX_COUNT_LINEBREAKS = 4;

export const backendValidationMapping = {
    "code" : "isvalid_code",
    "email" : "isvalid_email",
    "firstName" : "isvalid_firstname",
    "lastName" : "isvalid_lastname",
    "addressApartment" : "isvalid_appartment",
    "addressStreet" : "isvalid_street",
    "addressCity" : "isvalid_city",
    "addressPostcode" : "isvalid_zip",
    // "" : "isvalid_country",
    // "" : "isvalid_county",
    // "" : "isvalid_discrict",
    // "" : "isvalid_senderFirstname",
    // "" : "isvalid_senderLastname",
};

        