import React from 'react';

export const initialFormFieldsSender = () => {
    return {
        senderFirstname: "",
        senderLastname: "",
    }
}
export const initialFormFields = () => {
    
    const defaultValues = {
        email: "",
        lastname: "",
        firstname: "",
        appartment: "",
        street: "",
        city: "",
        zip: "",
        country: "",
        terms: "",
    }
    const defaultCounty = {
        county: "",
    }
    const defaultDistrict = {
        district: "",
    }

    switch (window.location.pathname.split("/")[1] || "") {

        case "en-MX" || "es-MX":
            return { ...defaultValues, ...defaultCounty, ...defaultDistrict };

        case "en-US" ||  "en-CA" ||  "fr-CA" ||  "en-AU":
            return { ...defaultValues, ...defaultCounty };

        default:
            return defaultValues;
    }
}

const FormFields = (props) => {

    const country = props.locale.split("-")[1];
    const emailFormField = (
        <label key="formfield_email" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_email === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelEmail") } *
            </span>
            <input required name="email"  onChange={ (e) => props.formElementChanged(e, "email") } type="email" className="formElement-input"  minLength="5"  />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrEmail") }</div>
        </label>
    );

    let combinedFormFields = [];

    const defaultFormFields= [
        <label key="formfield_firstname" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_firstname === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelFirstname") } *
            </span>
            <input name="firstname" onChange={ (e) => props.formElementChanged(e, "firstname") } type="text" className="formElement-input"  minLength="2" required  />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrFirstname") }</div>
        </label>,
        <label key="formfield_lastname" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_lastname === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelLastname") }  *
            </span>
            <input name="lastname" onChange={ (e) => props.formElementChanged(e, "lastname") } type="text" className="formElement-input"  minLength="2" required  />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrLastname") }</div>
        </label>,
        <label key="formfield_appartment" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_appartment === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelAppartment") } 
            </span>
            <input name="appartment" onChange={ (e) => props.formElementChanged(e, "appartment") } type="text" className="formElement-input" />
            <div className="formElement-errmsg">{ props.t.t("addressFormLabelAErrment") }</div>
        </label>,
        <label key="formfield_street" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_street === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelStr") }  *
            </span>
            <input  name="street" onChange={ (e) => props.formElementChanged(e, "street") } type="text" className="formElement-input" required { ...countryRequirements[country].str }  />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrStr") }</div>
        </label>,
        <label key="formfield_city" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_city === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelCity") }  *
            </span>
            <input  name="city" onChange={ (e) => props.formElementChanged(e, "city") } type="text" className="formElement-input" { ...countryRequirements[country].city } />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrCity") }</div>
        </label>,
        <label key="formfield_zip" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_zip === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelZip") }  *
            </span>
            <input name="zip" onChange={ (e) => props.formElementChanged(e, "zip") } type="text" className="formElement-input"  required { ...countryRequirements[country].zip }  />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrZip") }</div>
        </label>,
        <label key="formfield_country" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_country === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelCountry") }  *
            </span>
            <input required name="country" onChange={ (e) => props.formElementChanged(e, "country") } type="text" className="formElement-input"  disabled="disabled"  { ...countryRequirements[country].country } />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrCountry") }</div>
        </label>,
        props.receiver === "myself" ? emailFormField : null
    ];

    const formElemCounty = (
        <label key="formfield_county" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_county === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelCounty") } *
            </span>
            <select name="county" onChange={ (e) => props.formElementChanged(e, "county") } type="text" className="formElement-input" required minLength="1" maxLength="40" >
                { country === "AU" && 
                    <>
                        <option value="">{ props.t.t("pleaseSelect") }</option><option value="ACT">ACT</option><option value="NSW">NSW</option><option value="NT">NT</option>
                        <option value="QLD">QLD</option><option value="SA">SA</option><option value="TAS">TAS</option><option value="VIC">VIC</option><option value="WA">WA</option>
                    </>
                }
                { country === "CA" && 
                    <>
                        <option value="">{ props.t.t("pleaseSelect") }</option><option value="AB">AB</option><option value="BC">BC</option><option value="MB">MB</option><option value="NB">NB</option>
                        <option value="NL">NL</option><option value="NT">NT</option><option value="NS">NS</option><option value="NU">NU</option><option value="ON">ON</option>
                        <option value="PE">PE</option><option value="QC">QC</option><option value="SK">SK</option><option value="YT">YT</option>
                    </>
                }
                { country === "MX" && 
                    <>
                        <option value="">{ props.t.t("pleaseSelect") }</option><option value="AGS">Aguascalientes</option><option value="BC">Baja California</option><option value="BCS">Baja California S</option>
                        <option value="CHI">Chihuahua</option><option value="CHS">Chiapas</option><option value="CMP">Campeche</option><option value="COA">Coahuila</option>
                        <option value="COL">Colima</option><option value="DF">Ciudad de Mexico</option><option value="DGO">Durango</option><option value="GRO">Guerrero</option>
                        <option value="GTO">Guanajuato</option><option value="HGO">Hidalgo</option><option value="JAL">Jalisco</option><option value="MCH">Michoacán</option>
                        <option value="MEX">Mexico</option><option value="MOR">Morelos</option><option value="NAY">Nayarit</option><option value="NL">Nuevo Léon</option>
                        <option value="OAX">Oaxaca</option><option value="PUE">Puebla</option><option value="QR">Quintana Roo</option><option value="QRO">Querétaro</option>
                        <option value="SIN">Sinaloa</option><option value="SLP">San Luis Potosí</option><option value="SON">Sonora</option><option value="TAB">Tabasco</option>
                        <option value="TLX">Tlaxcala</option><option value="TMS">Tamaulipas</option><option value="VER">Veracruz</option><option value="YUC">Yucatán</option>
                        <option value="ZAC">Zacatecas</option>
                    </>
                }
                { country === "US" && 
                    <>
                        <option value="">{ props.t.t("pleaseSelect") }</option><option value="AL">AL</option><option value="AK">AK</option><option value="AS">AS</option><option value="AZ">AZ</option>
                        <option value="AR">AR</option><option value="AA">AA</option><option value="AE">AE</option><option value="AP">AP</option><option value="CA">CA</option>
                        <option value="CO">CO</option><option value="CT">CT</option><option value="DE">DE</option><option value="DC">DC</option><option value="FM">FM</option>
                        <option value="FL">FL</option><option value="GA">GA</option><option value="GU">GU</option><option value="HI">HI</option><option value="ID">ID</option>
                        <option value="IL">IL</option><option value="IN">IN</option><option value="IA">IA</option><option value="KS">KS</option><option value="KY">KY</option>
                        <option value="LA">LA</option><option value="ME">ME</option><option value="MH">MH</option><option value="MD">MD</option><option value="MA">MA</option>
                        <option value="MI">MI</option><option value="MN">MN</option><option value="MS">MS</option><option value="MO">MO</option><option value="MT">MT</option>
                        <option value="MP">MP</option><option value="NE">NE</option><option value="NV">NV</option><option value="NH">NH</option><option value="NJ">NJ</option>
                        <option value="NM">NM</option><option value="NY">NY</option><option value="NC">NC</option><option value="ND">ND</option><option value="OH">OH</option>
                        <option value="OK">OK</option><option value="OR">OR</option><option value="PW">PW</option><option value="PA">PA</option><option value="PR">PR</option>
                        <option value="RI">RI</option><option value="SC">SC</option><option value="SD">SD</option><option value="TN">TN</option><option value="TX">TX</option>
                        <option value="UT">UT</option><option value="VT">VT</option><option value="VI">VI</option><option value="VA">VA</option><option value="WA">WA</option>
                        <option value="WV">WV</option><option value="WI">WI</option><option value="WY">WY</option>
                    </>
                }
            </select>
            <div className="formElement-errmsg">{ props.t.t("addressFormErrCounty") }</div>
        </label>
    );

    const formElemDistrict = (
        <label key="formfield_district" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_discrict === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelDistrict") } *
            </span>
            <input required name="district" value="Deutschland" onChange={ (e) => props.formElementChanged(e, "district") } type="text" className="formElement-input"  minLength="1" disabled="disabled" />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrDistrict") }</div>
        </label>
    );

    const formElementsSenderData = [
        <h3 key="formSenderDataHl" className="u-textCenter u-marginBottomMd u-marginTopMd">{ props.t.t("addressFormHlMyAddr") }</h3>,
        <label key="formfield_senderFirstname" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_senderFirstname === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelSenderFirstname") } *
            </span>
            <input required name="senderFirstname"  onChange={ (e) => props.formElementChanged(e, "senderFirstname") } type="text" className="formElement-input"  minLength="1"  />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrSenderFirstname") }</div>
        </label>,
        <label key="formfield_senderLastname" className={ "formElement u-sm-size1of1 u-md-size1of2 " + ( (props.parentState.showValidation && props.parentState.isvalid_senderLastname === false) ? "formElement-hasError" : "")}>
            <span className="formElement-label">
                { props.t.t("addressFormLabelSenderLastname") } *
            </span>
            <input required name="senderLastname"  onChange={ (e) => props.formElementChanged(e, "senderLastname") } type="text" className="formElement-input"  minLength="1"  />
            <div className="formElement-errmsg">{ props.t.t("addressFormErrSenderLastname") }</div>
        </label>,
        props.receiver === "myself" ? null : emailFormField
    ];

    const formCheckboxes = [

        <label key="formfield_terms" className={ "formElement-checkbox u-marginTopMd u-size1of1  " + ( (props.parentState.showValidation && props.parentState.isvalid_terms === false) ? "formElement-hasError" : "")}>
            <input name="terms" onClick={ (e) => props.formElementChanged(e, "terms") } type="checkbox" className="formElement-input" required />
            <span className="formElement-label" dangerouslySetInnerHTML={{ __html: props.t.t("addressFormLabelTerms") }}></span>
            <div className="formElement-errmsg">{ props.t.t("addressForErrTerms") }</div>
        </label>,
    ]


    switch (props.locale) {
        case "en-MX" || "es-MX":
            combinedFormFields = [ ...defaultFormFields, formElemCounty, formElemDistrict];
            break;
            
        case "en-US" ||  "en-CA" ||  "fr-CA" ||  "en-AU":
            combinedFormFields = [ ...defaultFormFields, formElemCounty ];
            break;
            
        default:
            combinedFormFields = defaultFormFields;
            break;
    }
        
    if (props.receiver === "someoneelse"){ // add own address fields to form
        combinedFormFields = [ ...combinedFormFields, formElementsSenderData ];
    }

    return [ ...combinedFormFields, ...formCheckboxes ];
    

}

export default FormFields;



const countryRequirements = {
    "AU":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$",
        },
        country:{   value: "MISSING",   },
    },
    "NZ":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "KR": {
        str: {      minLength: 3, },
        city: {     required: "required",   },
        zip:{
            minLength: 5,
            maxLength: 6,
            // eslint-disable-next-line
            pattern: "^\d{5}$|^\d{6}$|^\d{3}\-\d{3}$",
        },
        country:{   value: "MISSING",   },
    },
    "BE":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "^(?:(?:[1-9])(?:\d{3}))$",
        },
        country:{   value: "MISSING",   },
    },
    "CZ":{
        city: {     required: "required",   },
        zip:{
            minLength: 6,
            maxLength: 6,
            // eslint-disable-next-line
            // eslint-disable-next-line
            pattern: "^[\d]{3}[\s-]?[\d]{2}$",
            placeholder: "123 45"
        },
        country:{   value: "MISSING",   },
    },
    "DK":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "DENMARK",   },
    },
    "DE": {
        str: {      
            // eslint-disable-next-line
            pattern: "[A-Z a-zßöäüÖÄÜ.,-]+[0-9]+[A-Z a-z]*"   
        },
        city: {     minLength: 3, },
        country:{   value: "GERMANY",   },
        zip:{
            minLength: 5,
            maxLength: 5,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },

    },
    "EE":{
        city: {     minLength: 3, },
        zip:{
            minLength: 5,
            maxLength: 5,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "ES":{
        city: {     minLength: 3, },
        zip:{
            minLength: 5,
            maxLength: 5,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "FI":{
        city: {     minLength: 3, },
        zip:{
            minLength: 5,
            maxLength: 5,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "FR":{
        city: {     minLength: 3, },
        zip:{
            minLength: 5,
            maxLength: 5,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "FRANCE",   },
    },
    "GR":{
        city: {     minLength: 3, },
        zip:{
            minLength: 6,
            maxLength: 6,
            // eslint-disable-next-line
            pattern: "^\d{2}\s{0,1}\d{3}$",
            placeholder: "123 45"
        },
        country:{   value: "MISSING",   },
    },
    "HU":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "IE":{
        city: {     minLength: 3, },
        zip:{
            minLength: 0,
            maxLength: 8,
            // eslint-disable-next-line
            pattern: "(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$",
        },
        country:{   value: "MISSING",   },
    },
    "IT":{
        city: {     minLength: 3, },
        zip:{
            minLength: 5,
            maxLength: 5,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "LV":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "^(LV-)[0-9]{4}$",
            placeholder: "LV-1234",
        },
        country:{   value: "MISSING",   },
    },
    "LT":{
        city: {     minLength: 3, },
        zip:{
            minLength: 5,
            maxLength: 8,
            // eslint-disable-next-line
            pattern: "^[Ll][Tt][- ]{0,1}\d{5}$|^\d{5}$",
            placeholder: "LT-12345"
        },
        country:{   value: "MISSING",   },
    },
    "LU":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 6,
            // eslint-disable-next-line
            pattern: "^[L][- ]{0,1}\d{4}$|^\d{4}$",
        },
        country:{   value: "MISSING",   },
    },
    "NL":{
        city: {     minLength: 3, },
        zip:{
            minLength: 7,
            maxLength: 7,
            // eslint-disable-next-line
            pattern: "^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$",
            placeholder: "1234 AB"
        },
        country:{   value: "MISSING",   },
    },
    "NO":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "AT":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "PL":{
        city: {     minLength: 3, },
        zip:{
            minLength: 6,
            maxLength: 6,
            // eslint-disable-next-line
            pattern: "^\d{2}[- ]{0,1}\d{3}$",
            placeholder: "12-345"
        },
        country:{   value: "MISSING",   },
    },
    "PT":{
        city: {     minLength: 3, },
        zip:{
            minLength: 8,
            maxLength: 8,
            // eslint-disable-next-line
            pattern: "^\d{4}[- ]{0,1}\d{3}$",
            placeholder: "1234-456"
        },
        country:{   value: "MISSING",   },
    },
    "CH":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "SI":{
        city: {     minLength: 3, },
        zip:{
            minLength: 4,
            maxLength: 4,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "SK":{
        city: {     minLength: 3, },
        zip:{
            minLength: 6,
            maxLength: 6,
            // eslint-disable-next-line
            pattern: "[0-9^\d]{3}[ ]{1}[0-9]{2}",
            placeholder: "123 45"
        },
        country:{   value: "MISSING",   },
    },
    "SE":{
        city: {     minLength: 3, },
        zip:{
            minLength: 6,
            maxLength: 6,
            // eslint-disable-next-line
            pattern: "^[\d]{3}[\s-]?[\d]{2}$",
            placeholder: "123 45"
        },
        country:{   value: "MISSING",   },
    },
    "GB":{
        city: {     minLength: 3, },
        zip:{
            minLength: 6,
            maxLength: 8,
            // eslint-disable-next-line
            pattern: "^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$",
            placeholder: "L1 8JQ",
        },
        country:{   value: "UNITED KINGDOM",   },
    },
    "CA":{
        city: {     minLength: 3, },
        zip:{
            minLength: 7,
            maxLength: 7,
            // eslint-disable-next-line
            pattern: "^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$",
        },
        country:{   value: "MISSING",   },
    },
    "MX":{
        city: {     minLength: 3, },
        zip:{
            minLength: 5,
            maxLength: 5,
            // eslint-disable-next-line
            pattern: "[0-9]*",
        },
        country:{   value: "MISSING",   },
    },
    "US":{
        city: {     minLength: 3, },
        zip:{
            minLength: 5,
            maxLength: 10,
            // eslint-disable-next-line
            pattern: "^[0-9]{5}(-[0-9]{4})?$",
        },
        country:{   value: "UNITED STATES",   },
    },
}