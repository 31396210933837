import React from 'react';
import Header from '../components/Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { getEnvConfig } from '../env';

class Content extends React.Component {

    env = null;

    cLoadingFailed = "Loading failed.<br/><br/>";

    state = {
        content: "Loading..."
    }

    componentDidMount = () => {

        this.props.dispathUpdateInternalLocale();

        this.env = getEnvConfig(); 
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        if (this.props.locale){

            axios.get('/html/' + this.props.locale +'_'+ this.props.contentKey +'.html?cv='+Date.now())
                .then(res => {
                    // console.log(res)
                    if (res.status === 200 && res.data){

                        this.setState({ 
                            content: res.data.replace(/\[URL\]/g, "https://lego.com/"+ this.props.locale)
                        });

                    } else {
                        this.setState({ 
                            content: this.cLoadingFailed
                        });
                    }
                })
                .catch( (error) => {
                    console.error(error);
                    this.setState({ 
                        content: this.cLoadingFailed
                    });
                })
        
        }

        //tracking
        axios.post(this.env.apibase + 'tracking.php?cv='+Date.now(), { url: window.location.href }).then(res => {}).catch(e=>{})

    }

    render = () => {


        return (
            <>
                <Header />
                <div className="contentPadding limitcontent contentPage u-marginTopXl">
                    <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
                </div>
            </>
        )
    }

}
    


    
const mapStateToProp = state => ({
    locale: state.locale,
});


const mapDispatchToProps = dispatch => {
    return {
        dispathUpdateInternalLocale : () => dispatch({ type: "UPDATE_LOCALE" }),
    };
}


export default connect(mapStateToProp, mapDispatchToProps)(Content);    
    
    
    
    