import React from 'react'
import Slider from "react-slick";
import axios from 'axios';
import { connect } from 'react-redux';
import Composition from '../components/Composition';
import SliderNavigation from '../components/SliderNavigation';
import UnsavedChanges from '../components/UnsavedChanges';
import Finished from '../components/Finished';
import { BACKCOVER } from '../config';
import Options from '../components/Options';
import { getEnvConfig } from '../env';
import Header from '../components/Header';

class Configurator extends React.Component {

    state = {
        currentSlide: 0,
        tmpBackcoverCopy: "",
        showFinishedOverlay: false,
        wasFinishedLayerVisible: false,
        showSwipeInfo: true,

        hasUnsavedChanges: false,
        showUnsavedOverlay: false,
    }

    componentDidMount = () => {

        // scroll header out on mobile 
        let viewportWith = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if(viewportWith < 640){
            setTimeout(()=> {
                const formBlock = document.getElementsByTagName("header")[0];
                window.scrollTo({
                    top: formBlock.offsetHeight ,
                    left: 0,
                    behavior: 'smooth'
                })
            }, 2000);
        }

        // reset current slide to first slide
        this.props.updateCurrentSlide(0);


        //tracking
        axios.post(this.env.apibase + 'tracking.php?cv='+Date.now(), { url: window.location.href }).then(res => {}).catch(e=>{})
    }


    componentWillMount = () => {
        this.props.setConfiguratorStructure(this.props.match.params.theme);
        this.env = getEnvConfig();
        this.props.dispathUpdateInternalLocale();
    }
  


    // -------------- Slider
    
    sliderGoTo = (index, itemKey=null) => {

        if(this.state.hasUnsavedChanges){

            this.setState({ showUnsavedOverlay: true }) 

        } else {
            
            this.props.updateCurrentSlide(index)
            this.sliderMain.slickGoTo(index);
            if(itemKey === BACKCOVER){
                this.setState({ showSwipeInfo: false })
            }
        }
    }
    
    selectOption = (key, value, groupKey) => {
        if (key === BACKCOVER){
            this.setState({
                tmpBackcoverCopy: value,
            });
            this.props.updateSelectedOption(key, value, groupKey);
        } else {
            // all other values
            this.props.updateSelectedOption(key, value, groupKey);
        }
    }
    
    // -------------- TextHandling


    showFinishedOverlay = () => {
        if(this.state.hasUnsavedChanges){
            this.setState({ showUnsavedOverlay: true }) 
        } else {
            this.setState({ showFinishedOverlay: true }) 
        }

    }
    
    
       
    render = () => {

        const settingSliderMain = {
            arrows: false,
            dots: false,
            speed: 500,
            fade: true,
            cssEase: 'ease-in-out',
            swipe: false,
            // adaptiveHeight: true,
            
            beforeChange: (slick, currentSlide, nextSlide) => { 
                this.props.updateCurrentSlide(currentSlide);
            },

        };


        if (this.props.configurator.structure){

            return (
                <>
                    <Header showBackTheme={true} />
                    <div className="deskFrame">

                        <div className="deskFrame-wrap Grid">
                            <div className="u-sm-size1of1 u-md-size3of5">
                                <Composition 
                                    currentSlide={ this.state.currentSlide } 
                                    showFinishedIcon={ this.state.wasFinishedLayerVisible }
                                    showFinishedOverlay={ this.showFinishedOverlay }
                                    disableSwipeInfo={ ()=> this.setState({ showSwipeInfo: false }) }
                                    showSwipeInfo={ this.state.showSwipeInfo }
                                    showTextPlaceholders={ true }
                                />
                            </div>
                            <div className="u-sm-size1of1 u-md-size2of5 deskFrame-destopFix">
                                <Slider { ...settingSliderMain } ref={c => (this.sliderMain = c)} className="deskFrame-options">
                                    { this.props.configurator.structure.map( (item, index) =>  
                                        <Options 
                                            key={ "options"+index }
                                            item={ item }
                                            clickHandler={ this.selectOption }
                                            setUnsavedChanges={ newState => this.setState({ hasUnsavedChanges: newState }) }
                                        />
                                    )}
                                </Slider>
                            </div>
                            <SliderNavigation 
                                sliderGoTo={ this.sliderGoTo } 
                                showFinishedOverlay={ this.showFinishedOverlay }
                                theme={  this.props.configurator.structure }
                                currentSlide={ this.props.activeSlide }
                            />
                            { (this.state.showFinishedOverlay && !this.state.hasUnsavedChanges) && 
                                <Finished 
                                    hideOverlay={ () => this.setState({ showFinishedOverlay: false }) }
                                    t={ this.props.t }
                                    />
                                }
                            { this.state.showUnsavedOverlay && 
                                <UnsavedChanges 
                                    t={ this.props.t }
                                    hideOverlay={ () => this.setState({ showUnsavedOverlay: false }) }
                                />
                            }

                        </div>
                    </div>
                </>
            )
        } else {
            return null;
        }
    }
}
    


    
const mapStateToProp = state => ({
    composition : state.composition,
    t : state.t,
    activeSlide: state.activeSlide,
    configurator: state.configurator,
    locale: state.locale,
    finishedCats: state.finishedCats,
});

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedOption: (key, value, groupKey) => dispatch({ type: "UPDATE_SELECTED_OPTION", key: key, value: value, groupKey:groupKey }),
        updateCurrentSlide: (num) => dispatch({ type: "UPDATE_ACTIVE_SLIDE", slideNum: num }),
        setConfiguratorStructure: (id) => dispatch({ type: "SET_STRUCTURE", id: id }),
        dispathUpdateInternalLocale : () => dispatch({ type: "UPDATE_LOCALE" }),
    };
}
export default connect(mapStateToProp, mapDispatchToProps)(Configurator);    