import React from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import { getEnvConfig } from '../env';
import CompositionItem from './CompositionItem';

class FlipCard extends React.Component {

    env = null;

    state = {
        flipState: "card--unflip",
        isLoading: false,
        errorMsg: null,
        composition: {},
    }

    componentDidMount = () => {

        if (this.props.orderId){
            this.loadData();
        } else {
            this.setState({
                isLoading: false,
                errorMsg: this.props.t.t("shareOrderNotFound"),
            })
        }
    }

    loadData = () => {

        this.env = getEnvConfig();

        const data = {
            orderId: this.props.orderId,
        };

        // const res = { data: {"postcardBackCoverId":"backpage_0001","postcardFrontpageBackgroundId":"background_0001","postcardFrontpageThemeId":"ninjago","postcardFrontpageAvatarFaceId":"face_0001","postcardFrontpageAvatarHairstyleId":"hairColour_0001_hair_0001","postcardFrontpageAvatarOutfitBottomId":"","postcardFrontpageAvatarOutfitTopId":"outfit_top_0001","postcardFrontpageAvatarPropId":"prop_0001","postcardFrontpageAvatarSkinColorId":"skin_colour_0001","postcardFrontpageMessageTemplateId":"message_0001","postcardFrontpageStickerId":"sticker_0003","createdAt":"2021-04-15T09:51:57+02:00","externalIdentifier":"6077f09d82898"}}

        this.setState({ isLoading: true });

        axios.post(this.env.apibase + 'getOrderDetails.php?cv='+Date.now(), { data })
            .then(res => {
                // console.log(res)
                if (res.status === 200 && res.data.code !== 404){

                    this.props.setConfiguratorStructure(res.data.postcardFrontpageThemeId)

                    this.setState({
                        isLoading: false,
                        composition: {
                            bg: res.data.postcardFrontpageBackgroundId,
                            theme: res.data.postcardFrontpageThemeId,
                            face: res.data.postcardFrontpageAvatarFaceId,
                            outfitbottom: res.data.postcardFrontpageAvatarOutfitBottomId,
                            outfittop: res.data.postcardFrontpageAvatarOutfitTopId,
                            prop: res.data.postcardFrontpageAvatarPropId,
                            skincolor: res.data.postcardFrontpageAvatarSkinColorId,
                            stickers: res.data.postcardFrontpageStickerId,
                            backcover: res.data.postcardBackCoverId,
                            backcoverName: res.data.postcardBackText,
                            messageName: res.data.postcardFrontpageAvatarName,
                            message: res.data.postcardFrontpageMessageTemplateId,
                            color: res.data.postcardFrontpageAvatarHairstyleId.split("_")[0] + "_" + res.data.postcardFrontpageAvatarHairstyleId.split("_")[1],
                            hair_colored: res.data.postcardFrontpageAvatarHairstyleId.indexOf("hat_") < 0 ? res.data.postcardFrontpageAvatarHairstyleId.split("_")[2] + "_" + res.data.postcardFrontpageAvatarHairstyleId.split("_")[3] : res.data.postcardFrontpageAvatarHairstyleId ,
                        }
                    });

                } else {
                    this.setState({
                        isLoading: false,
                        errorMsg: this.props.t.t("shareOrderNotFound"),
                    });
                }
            })
            .catch( (error) => {
                // console.log(error);
                this.setState({
                    isLoading: false,
                    errorMsg: this.props.t.t("shareOrderNotFound"),
                });
            })
    }

    toggleFlipped = ()=> {
        if(this.state.flipState === ""){
            this.setState({ flipState: "card--flipped" });
        } else if(this.state.flipState === "card--flipped"){
            this.setState({ flipState: "card--unflip" });
        } else if(this.state.flipState === "card--unflip"){
            this.setState({ flipState: "card--flipped" });
        }
    }

    render = () => {
        // console.log(this.state.composition)

        if (this.state.errorMsg){

            return  (
                <div className="Grid u-marginTopMd u-marginBottomMd">
                    <div className="u-sm-size1of1 u-md-size2of3 u-md-before1of6 ">
                        <div className="msgError u-marginBottomLg">
                            { this.state.errorMsg }
                        </div>
                    </div>
                    <div  className="u-textCenter u-size1of1 u-marginTopMd">
                        <a target="_blank" href="https://www.lego.com/service" rel="noopener noreferrer" className="Button--default" >{ this.props.t.t("shareFailedButton") }</a>
                    </div>
                </div>
            );
        } else {

            return  (
                <div className="Grid u-marginTopMd u-marginBottomMd">
                    <div className={ "u-sm-size1of1 u-md-size2of3 u-md-before1of6 card-scene " + this.state.flipState  } onClick={ this.toggleFlipped }>
                        <div className="composition">
                            <div className="composition-list composition-list__hidePreview" >
                                { this.props.configurator && this.props.configurator.backgrounds  &&
                                    <div>
                                        <img decoding="async" width="2048" height="1448" src={ "../images/composition/" + this.props.configurator.themeName + "/background/" + ( this.state.composition.bg  || this.props.configurator.backgrounds[0] ) + ".png" } alt="" />
                                    </div>
                                }

                                { this.props.configurator && this.props.configurator.structure  && this.props.configurator.structure.map(
                                    (catItem, index) => <CompositionItem
                                        key={ catItem+index }
                                        catItem={ catItem }
                                        configurator={ this.props.configurator }
                                        composition={ this.state.composition }
                                        locale={ this.props.locale }
                                        index={ index }
                                        t={ this.props.t }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div  className="u-textCenter u-size1of1 u-marginTopMd">
                        <button className="Button--default" onClick={ this.toggleFlipped }>{ this.props.t.t("flipButton") }</button>
                    </div>
                </div>
            );
        }
    }
}


const mapStateToProp = state => ({
    t: state.t,
    configurator: state.configurator,
    locale: state.locale,
});

const mapDispatchToProps = dispatch => {
    return {
        setConfiguratorStructure: (id) => dispatch({ type: "SET_STRUCTURE", id: id }),
    };
}

export default connect(mapStateToProp, mapDispatchToProps)(FlipCard);




