import React from 'react'
import Slider from "react-slick";
import { connect } from 'react-redux';
import CompositionItem from './CompositionItem';

class Composition extends React.Component {
    
    componentDidMount = () => {
        setTimeout(()=> {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }, 1000);
    }

    render = () => {

        const settings = {
            dots: true, 
            arrow: false,
            draggable: true, 
            swipe: true, 
            infinite: true, 
            beforeChange: (slick, currentSlide, nextSlide) => { 
                console.log("bg", this.props.configurator.backgrounds[currentSlide] || null );
                this.props.updateSelectedOption("bg", this.props.configurator.backgrounds[currentSlide] || null );
                this.props.disableSwipeInfo();
            },
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        arrows: false, 
                  }
                },
            ]
        };

        if (this.props.configurator.structure){
            
            return (
                <div className="composition">

                    <div className="composition-inner" ref={c => (this.compositionSliderWrapper = c)} >
                        { this.props.previewMode ?
                            <img decoding="async" width="2048" height="1448" src={ "../images/composition/" + this.props.configurator.themeName + "/background/" + ( this.props.composition.bg  || this.props.configurator.backgrounds[0] ) + ".png" } alt="" />
                        :
                            <Slider {...settings} >
                                { this.props.configurator.backgrounds.map((item) => <img decoding="async" width="2048" height="1448" key={ item } src={ "../images/composition/" + this.props.configurator.themeName + "/background/" + item + ".png" } alt="" /> )}
                            </Slider>
                        }
                        <div className="composition-list" id="composition-list" ref={c => (this.compositionList = c)} >
                            { this.props.configurator.structure.map(
                                (catItem, index) => <CompositionItem 
                                    key={ catItem+index }
                                    catItem={ catItem }
                                    configurator={ this.props.configurator } 
                                    currentSlide={  this.props.previewMode ? 0 : this.props.currentSlide } // prevent to show backpage on addresse page
                                    composition={ this.props.composition }
                                    locale={ this.props.locale }
                                    index={ index }
                                    t={ this.props.t }
                                    showTextPlaceholders={ this.props.showTextPlaceholders || false }
                                />
                            )}
                        </div>
                    </div>

                    { this.props.showFinishedIcon && 
                        <div className="composition-finished" >
                            <img decoding="async" className="composition-finished-img" src= {  "../images/icon-check.png" } alt="" onClick={ this.props.showFinishedOverlay } />
                        </div>
                    }
                        
                    { !this.props.previewMode &&
                        <div className={ this.props.showSwipeInfo ? "composition-swipeinfo" : "composition-swipeinfo is-hidden" }>
                            { this.props.t.t("backgroundSwipe") }
                        </div>
                    }

                </div>
            )
        } else {
            return null;
        }
    }
}
    


    
const mapStateToProp = state => ({
    composition: state.composition,
    currentSlide: state.activeSlide,
    configurator: state.configurator,
    locale: state.locale,
    t: state.t,
});

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedOption: (key, value) => dispatch({ type: "UPDATE_SELECTED_OPTION", key: key, value: value }),
    };
}
export default connect(mapStateToProp, mapDispatchToProps)(Composition);    