import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Composition from '../components/Composition';
import FormFields, {
  initialFormFields,
  initialFormFieldsSender,
} from '../components/FormFields';
import { getEnvConfig } from '../env';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { backendValidationMapping } from '../config';

class Address extends React.Component {
  state = {
    errorMsg: null,
    isLoading: false,
    showValidation: false,
  };

  // recaptchaToken= null;
  env = null;
  externalOrderId = null;

  componentDidMount = () => {
    this.env = getEnvConfig();
    this.prefillFormValidationStats();


    console.log(this.props.composition)
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    this.props.dispathUpdateInternalLocale();

    //tracking
    axios
      .post(this.env.apibase + 'tracking.php?cv=' + Date.now(), {
        url: window.location.href,
      })
      .then((res) => {})
      .catch((e) => {});

    // console.log(this.props.composition)
  };

  prefillFormValidationStatsSender = () => {
    this.prefillFormValidationStats(initialFormFieldsSender());
  };

  prefillFormValidationStats = (fields = initialFormFields()) => {
    const fieldKeys = Object.keys(fields);
    var newFieldStateCollection = {};
    for (var i = 0; i < fieldKeys.length; i++) {
      newFieldStateCollection['isvalid_' + fieldKeys[i]] =
        fieldKeys[i] === 'appartment' ||
        fieldKeys[i] === 'company' ||
        fieldKeys[i] === 'country'
          ? true
          : false;
    }
    this.setState({ ...newFieldStateCollection });
  };

  setReceiver = (rec) => {
    // initial prefilled state update
    if (!this.props.receiver) {
      setTimeout(() => {
        this.updateAllValidationStates();
      }, 500);
    }

    setTimeout(() => {
      const formBlock = document.getElementsByClassName('blockAnimation');
      window.scrollTo({
        top: formBlock[0].offsetTop - 50,
        left: 0,
        behavior: 'smooth',
      });
    }, 1200);
    this.props.updateReceiver(rec);

    // handle addtional form fields
    if (rec === 'someoneelse') {
      this.prefillFormValidationStatsSender();
    }
  };

  // -----------------------------------------------------------

  // initial field validation for returing visitors

  updateAllValidationStates = () => {
    const inputs = [
      ...this.addressForm.getElementsByTagName('input'),
      ...this.addressForm.getElementsByTagName('select'),
    ];

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value && inputs[i].name) {
        this.formElementChanged({ target: inputs[i] }, inputs[i].name);
      }
    }
  };

  allFieldsValid = () => {
    let formFields = initialFormFields();
    if (this.props.receiver === 'someoneelse') {
      formFields = { ...formFields, ...initialFormFieldsSender() };
    }

    const fieldKeys = Object.keys(formFields);
    var allValid = true;

    for (var i = 0; i < fieldKeys.length; i++) {
      // check for undefined is neccessary for removeing additional form fields
      if (this.state['isvalid_' + fieldKeys[i]] === false) {
        allValid = false;
        break;
      }
    }

    return allValid;
  };

  submit = (e) => {
    e.preventDefault();

    if (this.allFieldsValid()) {
      // finish order
      this.createOrder();
    } else {
      this.setState({ showValidation: true });
      this.scrollToErrorField();
    }
  };

  // code reactivation
  sendAbortRequest = () => {
    const data = {
      externalOrderId: this.externalOrderId,
    };

    axios
      .post(this.env.apibase + 'abort.php?cv=' + Date.now(), { data })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createOrder = () => {
    const data = {
      // composition: this.props.composition,
      address: this.props.address,
      // receiver: this.props.receiver,
      locale: this.props.locale,
      orderItems: this.props.composition,
    };

    this.setState({ isLoading: true });

    axios
      .post(this.env.apibase + 'order.php?cv=' + Date.now(), { data })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.orderItems && res.data.orderItems[0].externalIdentifier) {
            this.externalOrderId = res.data.orderItems[0].externalIdentifier;
            
            window.location.pathname =
              '/' +
              this.props.locale +
              '/thankyou/' +
              res.data.orderItems[0].externalIdentifier +
              '/' +
              this.props.receiver +
              '/' +
              this.props.address.firstname +
              '/' +
              (this.props.address.senderFirstname || '');

          } else {
            if (res.data.errors) {
              // console.log(res.data.errors.children)
              // errorKeys = Object.keys(res.data.errors.children)
              for (const key in res.data.errors.children) {
                if (
                  res.data.errors.children[key].errors &&
                  backendValidationMapping[key]
                ) {
                  // console.log("---", backendValidationMapping[key], false)
                  this.setState({
                    [backendValidationMapping[key] + '_errMsg']:
                      res.data.errors.children[key].errors.join(' '),
                    [backendValidationMapping[key]]: false,
                    showValidation: true,
                  });
                }
              }
            }

            this.setState({
              isLoading: false,
              errorMsg: this.props.t.t('addressOrderFailed'),
            });
          }
        } else {
          this.setState({
            isLoading: false,
            errorMsg: this.props.t.t('addressOrderFailed'),
          });
        }

        this.scrollToErrorField();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          errorMsg: this.props.t.t('addressConnectionFailed'),
        });
      });
  };

  // submitOrderItem = () => {
  //   const data = {
  //     composition: this.props.composition,
  //     // address: this.props.address,
  //     externalOrderId: this.externalOrderId,
  //     // receiver: this.props.receiver,
  //     locale: this.props.locale,
  //   };

  //   this.setState({ isLoading: true });

  //   axios
  //     .post(this.env.apibase + 'orderitem.php?cv=' + Date.now(), { data })
  //     .then((res) => {
  //       if (res.data.externalIdentifier) {
  //         window.location.pathname =
  //           '/' +
  //           this.props.locale +
  //           '/thankyou/' +
  //           res.data.externalIdentifier +
  //           '/' +
  //           this.props.receiver +
  //           '/' +
  //           this.props.address.firstname +
  //           '/' +
  //           (this.props.address.senderFirstname || '');
  //       } else {
  //         this.setState({
  //           isLoading: false,
  //           errorMsg: this.props.t.t('addressOrderFailed'),
  //         });
  //         this.sendAbortRequest();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       this.setState({
  //         isLoading: false,
  //         errorMsg: this.props.t.t('addressConnectionFailed'),
  //       });
  //       this.sendAbortRequest();
  //     });
  // };

  scrollToErrorField = () => {
    setTimeout(() => {
      let errField = document.getElementsByClassName('formElement-hasError');
      if (errField.length > 0) {
        window.scrollTo({
          top: errField[0].offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 50);
  };

  capitalizeFirstLetter = (str) => {
    str = str.toLowerCase()
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  capitalizeEachWord = (str) => {
    str = str.toLowerCase()
    var words = str.split(' ');
    for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
  }

  formElementChanged = (e, key) => {
    // rewrite for checkboxes
    if (e.target.type === 'checkbox') {
      e.target.value = e.target.checked === true ? e.target.checked : null;
    }

    let fieldValue = e.target.value
  
    // capitalize first char
    if( key ==="senderFirstname" || key ==="senderLastname" || key ==="district"){
      fieldValue = this.capitalizeFirstLetter(fieldValue)
    }
    // capitalize first char in all words
    if(key === "firstname" || key === "lastname"|| key === "city" || key === "street"){
      fieldValue = this.capitalizeEachWord(fieldValue)
    }
    // capitalize all
    if(key === "zip"){
      fieldValue = fieldValue.toUpperCase()
    }

    this.props.updateAddress(key, fieldValue);

    const value = e.target.value !== '' ? e.target.value : null;
    const pattern =
      e.target.pattern === '' || e.target.maxLength === undefined
        ? '.*'
        : e.target.pattern;
    const minLength = e.target.minLength ? e.target.minLength : 0;
    const maxLength =
      e.target.maxLength === -1 || e.target.maxLength === undefined
        ? 200
        : e.target.maxLength;

    let isFieldInvalid = true;

    // check pattern
    if (value) {
      const match = value.match(pattern);
      if (match && match[0] === value) {
        // check min length
        if (value.length >= parseInt(minLength)) {
          // check max length
          if (value.length <= parseInt(maxLength)) {
            isFieldInvalid = false;
          }
        }
      }
    }

    // check validation
    this.setState({ ['isvalid_' + key]: !isFieldInvalid });
  };

  render = () => {
    if (
      this.props.configurator.structure ||
      window.location.host === 'localhost:3000'
    ) {
      return (
        <>
          <Header />
          <div className="deskFrame address">
            <form
              onSubmit={this.submit}
              noValidate={true}
              ref={(ref) => (this.addressForm = ref)}
              className="deskFrame-wrap "
            >
              <div className="u-textCenter">
                <div className="deskFrame-indent">
                  <h2>{this.props.t.t('addressComposHeadline')}</h2>
                  <p>{this.props.t.t('addressComposCopy1')}</p>
                  <div className="Grid u-marginTopMd u-marginBottomMd">
                    <div className="u-sm-size1of1 u-md-size1of2 u-md-before1of4">
                      <Composition previewMode={true} />
                    </div>
                  </div>

                  <p>{this.props.t.t('addressComposCopy2')}</p>
                  <p className="u-marginTopSm">
                    {this.props.t.t('addressComposCopy3_1')}{' '}
                    <Link to={'create/' + this.props.configurator.themeName}>
                      {this.props.t.t('addressComposCopy3_2')}
                    </Link>
                    {this.props.t.t('addressComposCopy3_3')}
                  </p>
                </div>
              </div>
              <div className="deskFrame-bgYellow ">
                <div className="deskFrame-indent">
                  <label
                    className={
                      'formElement u-size1of1 ' +
                      (this.state.showValidation &&
                      this.state.isvalid_code === false
                        ? 'formElement-hasError'
                        : '')
                    }
                  >
                    <span className="formElement-label">
                      {this.props.t.t('addressPromoLabel')}
                    </span>
                    <input
                      name="code"
                      placeholder={this.props.t.t('addressPromoPlaceholder')}
                      defaultValue={this.props.address.code}
                      onChange={(e) => this.formElementChanged(e, 'code')}
                      type="text"
                      className="formElement-input"
                      required
                      minLength="8"
                      maxLength="8"
                      pattern="[A-Za-z0-9]*"
                    />
                    <div className="formElement-errmsg">
                      {this.props.t.t('addressPromoError')}{' '}
                      {this.state.isvalid_code_errMsg || ''}
                    </div>
                  </label>

                  <h3 className="u-textCenter u-marginTopMd u-marginBottomMd">
                    {this.props.t.t('addressPromoHelp')}
                  </h3>

                  <div className="Grid">
                    <div
                      className={
                        'u-sm-size1of1 u-md-size1of2 Grid u-flexAlignItemsCenter Button Button--' +
                        (this.props.receiver === 'myself'
                          ? 'orange'
                          : 'default')
                      }
                      onClick={() => this.setReceiver('myself')}
                    >
                      <span className="u-sizeFill">
                        {this.props.t.t('addressPromoOption1')}
                      </span>
                    </div>

                    <div
                      className={
                        'u-sm-size1of1 u-md-size1of2 Grid u-flexAlignItemsCenter Button Button--' +
                        (this.props.receiver === 'someoneelse'
                          ? 'orange'
                          : 'default')
                      }
                      onClick={() => this.setReceiver('someoneelse')}
                    >
                      <span className="u-sizeFill">
                        {this.props.t.t('addressPromoOption2')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {this.props.receiver && (
                <div className="blockAnimation">
                  <div className="deskFrame-indent">
                    <h3 className="u-textCenter u-marginBottomMd">
                      {this.props.receiver === 'myself'
                        ? this.props.t.t('addressFormHlMyAddr')
                        : this.props.t.t('addressFormHlReceiverAddr')}
                    </h3>

                    <div className="Grid Grid--withGutter">
                      <FormFields
                        formElementChanged={this.formElementChanged}
                        locale={this.props.locale}
                        address={this.props.address}
                        parentState={this.state}
                        t={this.props.t}
                        receiver={this.props.receiver}
                      />
                    </div>

                    {this.state.errorMsg && (
                      <div className="msgError u-marginBottomLg u-marginTopLg">
                        {this.state.errorMsg}
                      </div>
                    )}

                    <div className="u-textCenter u-marginTopLg">
                      <button
                        className="Button Button--default"
                        type="submit"
                        disabled={this.state.isLoading ? 'disabled' : null}
                      >
                        {this.props.t.t('addressFormSubmit')}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </>
      );
    } else {
      return (
        <div className="deskFrame">
          <div className="deskFrame-wrap ">
            <div className="u-textCenter">
              Data missing, sorry. Please reload the page.
              <br />
              <br />
              <a href="/" className="Button--orange">
                reload
              </a>
              &nbsp;
            </div>
          </div>
        </div>
      );
    }
  };
}

const mapStateToProp = (state) => ({
  address: state.address,
  composition: state.composition,
  receiver: state.receiver,
  configurator: state.configurator,
  locale: state.locale,
  t: state.t,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateAddress: (key, value) =>
      dispatch({ type: 'UPDATE_ADDRESS', key: key, value: value }),
    updateReceiver: (receiver) =>
      dispatch({ type: 'UPDATE_RECEIVER', receiver: receiver }),
    dispathUpdateInternalLocale: () => dispatch({ type: 'UPDATE_LOCALE' }),
  };
};
export default connect(mapStateToProp, mapDispatchToProps)(Address);
