import i18n from 'i18n-js';
import { translations } from '../translations';
import { initialFormFields, initialFormFieldsSender } from '../components/FormFields';
import { BaseStructureThemeFriends1, BackgroundsThemeFriends1 } from '../config_theme_friends1';
import { BaseStructureThemeFriends2, BackgroundsThemeFriends2 } from '../config_theme_friends2';
import { BaseStructureThemeFriends3, BackgroundsThemeFriends3 } from '../config_theme_friends3';
import { BaseStructureThemeFriends4, BackgroundsThemeFriends4 } from '../config_theme_friends4';
import { BaseStructureThemeNinjago, BackgroundsThemeNinjago } from '../config_theme_ninjago';
import { BaseStructureThemeMinifigures, BackgroundsThemeMinifigures } from '../config_theme_minifigures';
import { BaseStructureThemeCity, BackgroundsThemeCity } from '../config_theme_city';
import { BACKCOVER, MESSAGE, MAX_COUNT_LINEBREAKS } from '../config';

const getInitali18n = () => {
    // translation
    i18n.translations = translations;
    i18n.locale = window.location.pathname.split("/")[1];
    i18n.defaultLocale = "en-GB";
    return i18n;

}
const getInitalFinishedCat = structure => {
    return [structure[0].itemKey];

}
const getInitalConfiguratorValues = structure => {
    let initValues = {};

    for (var i = 0; i < structure.length; i++) {
        if(structure[i].components){
            for (var j = 0; j < structure[i].components.length; j++) {
                console.log(structure[i].components[j].key, structure[i].components[j].options[0])
                initValues[structure[i].components[j].key] = structure[i].components[j].options[0];
            }
        }
    }
    return initValues;
}



const initialState = {
    address: initialFormFields(),
    receiver: null,

    configurator: {
        themeName: "",
        structure: null,
        backgrounds: null,
    },

    composition: null,
    finishedCats: null,
    locale: null,

    t: {
        t : () => {}
    },
};

const reducer = (state = initialState, action) => {

    if (action.type === "UPDATE_LOCALE") {
        const newLocale = window.location.pathname.split("/")[1];
        return {
            ...state,
            locale: newLocale,
            t: getInitali18n(),
        }
        
    }


    // Slider synchronisation -------------------
    if (action.type === "UPDATE_ACTIVE_SLIDE") {

        return {
            ...state,
            activeSlide: action.slideNum,
        }
    }

    // Address -------------------


    if (action.type === "UPDATE_ADDRESS") {

        return {
            ...state,
            address: {
                ...state.address,
                [action.key]: action.value,
            },
        }
    }
    if (action.type === "UPDATE_RECEIVER") {

        let muteAddr = state.address;

        // toggle handling for receiver
        if(action.receiver === "someoneelse"){ // if  addtional form fields needed
            muteAddr = {
                ...state.address,
                ...initialFormFieldsSender(),
            }
        }

        // if war someoneelse before and now myself
        if (action.receiver === "myself" && state.receiver === "someoneelse"){
            // todo: make dynamtic with initialFormFieldsSender()
            delete muteAddr.senderEmail;
            delete muteAddr.senderFirstname;
            delete muteAddr.senderLastname;
        }

        return {
            ...state,
            receiver: action.receiver,
            address: muteAddr,
        }

    }

    // Compositon -------------------


    if (action.type === "RESET_COMPOSITION") {

        return {
            ...state,
            composition: null,
        }

    }
    
    if (action.type === "UPDATE_SELECTED_OPTION") {

        // rewrite finished cat, to fix text input complete flag
        let newFinishedCat = action.groupKey;
        if(newFinishedCat === "backcoverName"){
            newFinishedCat = BACKCOVER;

            action.value = (action.value || "").split(/\r?\n|\r/g).map((item, key) => {
                return key+1 < MAX_COUNT_LINEBREAKS ? item+"\r\n" : item+" ";
            }).join("");
        }
        if(newFinishedCat === "messageName"){
            newFinishedCat = MESSAGE;
        }

        return {
            ...state,
            finishedCats: newFinishedCat ? [...new Set([...state.finishedCats, newFinishedCat])] : state.finishedCats,
            composition: {
                ...state.composition,
                [action.key]: action.value,
            },
        }
    }


    if (action.type === "SET_STRUCTURE") {


        switch (action.id) {
            case "friends1":
                return {
                    ...state,
                    configurator: {
                        themeName: action.id,
                        structure: BaseStructureThemeFriends1,
                        backgrounds: BackgroundsThemeFriends1,
                    },
                    composition: state.composition ? state.composition : {
                        ...getInitalConfiguratorValues(BaseStructureThemeFriends1),
                        bg: BackgroundsThemeFriends1[0],
                        theme: action.id,
                    },
                    finishedCats: getInitalFinishedCat(BaseStructureThemeFriends1),
                }
        
            case "friends2":
                return {
                    ...state,
                    configurator: {
                        themeName: action.id,
                        structure: BaseStructureThemeFriends2,
                        backgrounds: BackgroundsThemeFriends2,
                    },
                    composition: state.composition ? state.composition : {
                        ...getInitalConfiguratorValues(BaseStructureThemeFriends2),
                        bg: BackgroundsThemeFriends2[0],
                        theme: action.id,
                    },
                    finishedCats: getInitalFinishedCat(BaseStructureThemeFriends2),
                }
        
            case "friends3":
                return {
                    ...state,
                    configurator: {
                        themeName: action.id,
                        structure: BaseStructureThemeFriends3,
                        backgrounds: BackgroundsThemeFriends3,
                    },
                    composition: state.composition ? state.composition : {
                        ...getInitalConfiguratorValues(BaseStructureThemeFriends3),
                        bg: BackgroundsThemeFriends3[0],
                        theme: action.id,
                    },
                    finishedCats: getInitalFinishedCat(BaseStructureThemeFriends3),
                }
            case "friends4":
                return {
                    ...state,
                    configurator: {
                        themeName: action.id,
                        structure: BaseStructureThemeFriends4,
                        backgrounds: BackgroundsThemeFriends4,
                    },
                    composition: state.composition ? state.composition : {
                        ...getInitalConfiguratorValues(BaseStructureThemeFriends4),
                        bg: BackgroundsThemeFriends4[0],
                        theme: action.id,
                    },
                    finishedCats: getInitalFinishedCat(BaseStructureThemeFriends4),
                }
            case "ninjago":
                return {
                    ...state,
                    configurator: {
                        themeName: action.id,
                        structure: BaseStructureThemeNinjago,
                        backgrounds: BackgroundsThemeNinjago,
                    },
                    composition: state.composition ? state.composition : {
                        ...getInitalConfiguratorValues(BaseStructureThemeNinjago),
                        bg: BackgroundsThemeNinjago[0],
                        theme: action.id,
                    },
                    finishedCats: getInitalFinishedCat(BaseStructureThemeNinjago),
                }
            case "city":
                return {
                    ...state,
                    configurator: {
                        themeName: action.id,
                        structure: BaseStructureThemeCity,
                        backgrounds: BackgroundsThemeCity,
                    },
                    composition: state.composition ? state.composition : {
                        ...getInitalConfiguratorValues(BaseStructureThemeCity),
                        bg: BackgroundsThemeCity[0],
                        theme: action.id,
                    },
                    finishedCats: getInitalFinishedCat(BaseStructureThemeCity),
                }
            case "minifigures":
                return {
                    ...state,
                    configurator: {
                        themeName: action.id,
                        structure: BaseStructureThemeMinifigures,
                        backgrounds: BackgroundsThemeMinifigures,
                    },
                    composition: state.composition ? state.composition : {
                        ...getInitalConfiguratorValues(BaseStructureThemeMinifigures),
                        bg: BackgroundsThemeMinifigures[0],
                        theme: action.id,
                    },
                    finishedCats: getInitalFinishedCat(BaseStructureThemeMinifigures),
                }
        
            default:
                return state;
        }
        
    }


    return state;
};

export default reducer;
