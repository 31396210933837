
const domains = {

    "lego.local": {
        "apibase" : "/stage/",
    },
    "localhost": {
        "apibase" : "/stage/",
    },
    "localhost:8080": {
        "apibase" : "/stage/",
    },
    "localhost:3000": {
        "apibase" : "https://int.fe.lego-postcard.campaign-loyalty.com/stage/",
    },
    "int.fe.lego-postcard.campaign-loyalty.com": {
        "apibase" : "/stage/",
    },
    "www.lego-postcard.com": {
        "apibase" : "/",
    },
    "lego-postcard.com": {
        "apibase" : "/",
    }
}

export const getEnvConfig = () => {
    return domains[window.location.host] || { "apibase" : "/" };
}
