import React from 'react'
import {BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import Configurator from './Nav/Configurator';
import Error from './Nav/Error';
import Address from './Nav/Address';
import Content from './Nav/Content';
import Home from './Nav/Home';
import Thankyou from './Nav/Thankyou';
import Share from './Nav/Share';
import { getEnvConfig } from './env';
// import { checkAllImagePaths } from './components/CheckAssets';
import i18n from 'i18n-js';
import { translations, availableTranslations } from './translations';
import { connect } from "react-redux";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { helper_getBrowserLocale } from './helper';

class App extends React.Component {

    env = null;

    // handle viewport change for changing tile count
    viewportState = null;
    viewportTimeout = null;
    
   
    componentDidMount = () => {
        
        // if (window.location.host === "localhost:3000"){
        //     checkAllImagePaths();
        // }

        this.env = getEnvConfig();

        // translation
        i18n.translations = translations;
        

        // handle viewport change
        this.setViewportState();
        window.onresize = ()=>{
            clearTimeout(this.viewportTimeout);
            this.viewportTimeout = setTimeout( ()=>{
                this.setViewportState();
            }, 250);
        } 
        
    }

    setViewportState = () => {
        let viewportWith = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        clearTimeout(this.viewportTimeout);
        
        if (viewportWith === null){ // set initial value

            if(viewportWith > 640){
                this.viewportState = "desktop";
            } else {
                this.viewportState = "mobile";
            }

        } else {

            var newViewportState = "mobile";

            if(viewportWith > 640){
                newViewportState = "desktop";
            }

            if (this.viewportState !== newViewportState){
                this.viewportState = newViewportState;
                this.forceUpdate();
            }
        }
    }
    
    render = () => {

        
        if(window.location.pathname.split("/").length > 2){
            // check if url locale ISO format is correct xx-XX
            const urlLocale = window.location.pathname.split("/")[1];
            // // check that locale is available
            if(availableTranslations.includes(urlLocale) === false){
                window.location.pathname = "/" + helper_getBrowserLocale() + "/";
            } 
        }


        return (
            <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>

                <article>

                    { window.location.pathname === "/" &&
                        <Redirect to={ "/" + helper_getBrowserLocale() + "/" } />
                    }

                    <Switch>
                        <Route path="/null/" component={ Error } />
                        <Route path="/:locale/" component={ Home } exact />
                        <Route path="/:locale/create/:theme" component={ Configurator } />
                        <Route path="/:locale/address" component={ Address }  />
                        <Route path="/:locale/terms" component={ ()=> <Content contentKey="terms" /> } />
                        <Route path="/:locale/about" component={ ()=> <Content contentKey="imprint" /> } />
                        <Route path="/:locale/faq" component={ ()=> <Content contentKey="faq" /> } />
                        <Route path="/:locale/thankyou/:orderid/:receiver/:firstname/:senderFirstname" component={ Thankyou } />
                        <Route path="/:locale/thankyou/:orderid/:receiver/:firstname" component={ Thankyou } />
                        <Route path="/:locale/share/:orderid" component={ Share } />
                        <Route component={ Error } />
                    </Switch>
                </article>

                <footer>
                    <ul>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.lego.com/service">{ this.props.t.t("footerContact")}</a></li>
                        <li>·</li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.lego.com/legal/notices-and-policies/privacy-policy">{ this.props.t.t("footerPrivacy")}</a></li>
                        <li>·</li>
                        <li><Link to={ "/" + this.props.locale + "/faq"}>{ this.props.t.t("footerFAQ")}</Link></li>
                        <li>·</li>
                        <li><Link to={ "/" + this.props.locale + "/terms"}>{ this.props.t.t("footerTerms")}</Link></li>
                        <li>·</li>
                        <li><Link to={ "/" + this.props.locale + "/about"}>{ this.props.t.t("footerImprint")}</Link></li>
                    </ul>
                </footer>
                
            </BrowserRouter>
        )
    }

}
    

const mapStateToProps = state => {
    return {
        locale: state.locale,
        address: state.address,
        t: state.t,
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);

    

    