import React from 'react'
import { connect } from 'react-redux';


class UnsavedChanges extends React.Component {
    
    state ={
    }
    
    render = () => {
        return (
            <div className="overlay overlay-finished Grid Grid--withGutter u-flexAlignItemsCenter u-flexJustifyCenter">
                <div className="u-size1of1 u-textCenter">
                    <h2>{ this.props.t.t("unsavedHeadline") }</h2>
                    <p>
                        {this.props.t.t("unsavedCopy") }
                    </p>
                    <span className="Button--orange"  onClick={ this.props.hideOverlay }>{ this.props.t.t("unsavedBtn") }</span>
                </div>
            </div>
        )
    }
}
    


    
const mapStateToProp = state => ({
    composition: state.composition,
    locale: state.locale,
});

const mapDispatchToProps = dispatch => {
    return {
    };
}
export default connect(mapStateToProp, mapDispatchToProps)(UnsavedChanges);    