import { BACKCOVER, MESSAGE } from "./config"

export const BackgroundsThemeMinifigures = [
	"background_0001",
	"background_0002",
	"background_0003",
	"background_0004",
	"background_0005",
	"background_0006",
	"background_0007",
	"background_0008",
	"background_0009",
	"background_0010",
	"background_0011",
]
export const BaseStructureThemeMinifigures = [
	{
		headline: "optionHeadlineOutfitTop",
		itemKey: "outfittop",
		basePath: "outfit_top/",
		catImg: "../images/composition/minifigures/menu/menuIcon_0003.png",
		components: [
			{
				key: "outfittop",
				rowsToShow: 4,
				options: [
					"outfit_top_0001",
					"outfit_top_0002",
					"outfit_top_0003",
					"outfit_top_0004",
					"outfit_top_0005",
					"outfit_top_0006",
					"outfit_top_0007",
					"outfit_top_0008",
					"outfit_top_0009",
					"outfit_top_0010",
					"outfit_top_0011",
					"outfit_top_0012",
					"outfit_top_0013",
					"outfit_top_0014",
					"outfit_top_0015",
					"outfit_top_0016",
					"outfit_top_0017",
					"outfit_top_0018",
					"outfit_top_0019",
					"outfit_top_0020",
					"outfit_top_0021",
					"outfit_top_0022",
					"outfit_top_0023",
					"outfit_top_0024",
					"outfit_top_0025",
					"outfit_top_0026",
					"outfit_top_0027",
					"outfit_top_0028",
					"outfit_top_0029",
					"outfit_top_0030",
					"outfit_top_0031",
					"outfit_top_0032",
					"outfit_top_0033",
					"outfit_top_0034",
					"outfit_top_0035",
					"outfit_top_0036",
					"outfit_top_0037",
					"outfit_top_0038",
					"outfit_top_0039",
					"outfit_top_0040",
					"outfit_top_0041",
					"outfit_top_0042",
					"outfit_top_0043",
					"outfit_top_0044",
					"outfit_top_0045",
					"outfit_top_0046",
					"outfit_top_0047",
					"outfit_top_0048",
					"outfit_top_0049",
					"outfit_top_0050",
					"outfit_top_0051",
					"outfit_top_0052",
					"outfit_top_0053",
					"outfit_top_0054",
					"outfit_top_0055",
					"outfit_top_0056",
					"outfit_top_0057",
					"outfit_top_0058",
					"outfit_top_0059",
					"outfit_top_0060",
					"outfit_top_0061",
					"outfit_top_0062",
					"outfit_top_0063",
					"outfit_top_0064",
				],
			},
		],
	},
	{
		headline: "optionHeadlineSkincolor",
		itemKey: "skincolor",
		basePath: "skin_colour/",
		catImg: "https://dummyimage.com/100x100/ededed/808080?text=skincolor",
		components: [
			{
				key: "skincolor",
				rowsToShow: 4,
				options: ["skin_colour_0001"],
			},
		],
	},
	{
		headline: "optionHeadlineFace", // visible headline
		itemKey: "face", // state id for overall slider
		basePath: "face/", // folder where you can find images
		catImg: "../images/composition/minifigures/menu/menuIcon_0001.png", // image for overall slider
		components: [
			{
				key: "face",
				rowsToShow: 4,
				options: [
					"face_0001",
					"face_0002",
					"face_0003",
					"face_0004",
					"face_0005",
					"face_0006",
					"face_0007",
					"face_0008",
					"face_0009",
					"face_0010",
					"face_0011",
					"face_0012",
					"face_0013",
					"face_0014",
					"face_0015",
					"face_0016",
					"face_0017",
					"face_0018",
					"face_0019",
					"face_0020",
					"face_0021",
					"face_0022",
					"face_0023",
					"face_0024",
					"face_0025",
					"face_0026",
					"face_0027",
					"face_0028",
					"face_0029",
					"face_0030",
					"face_0031",
					"face_0032",
					"face_0033",
					"face_0034",
					"face_0035",
					"face_0036",
					"face_0037",
					"face_0038",
					"face_0039",
					"face_0040",
					"face_0041",
					"face_0042",
					"face_0043",
					"face_0044",
					"face_0045",
					"face_0046",
					"face_0047",
					"face_0048",
					"face_0049",
					"face_0050",
					"face_0051",
					"face_0052",
					"face_0053",
					"face_0054",
					"face_0055",
					"face_0056",
					"face_0057",
					"face_0058",
					"face_0059",
					"face_0060",
					"face_0061",
					"face_0062",
					"face_0063",
					"face_0064",
				],
			},
		],
	},
	{
		headline: "optionHeadlineHaircolor", // visible headline
		itemKey: "hairstyle", // state id for overall slider
		basePath: "hair/", // folder where you can find images
		catImg: "../images/composition/minifigures/menu/menuIcon_0002.png", // image for overall slider
		combinedKeyMapping: "[color]_[hair_colored]",
		components: [
			{
				key: "color",
				rowsToShow: 1,
				borderRadius: true, // round icon 50% border radius
				options: [
					"hairColour_0001",
					"hairColour_0002",
					"hairColour_0003",
					"hairColour_0004",
					"hairColour_0005",
					"hairColour_0006",
					"hairColour_0007",
					"hairColour_0008",
					"hairColour_0009",
					"hairColour_0010",
				],
			},
			{
				key: "hair_colored", // state id for single items
				rowsToShow: 3, // visible rows in slider
				combinedSubKeyMapping: "[color]_[DYN]", // needed for the thumbs in combination with the color
				options: [
					"hair_0001",
					"hair_0002",
					"hair_0003",
					"hair_0004",
					"hair_0005",
					"hair_0006",
					"hair_0007",
					"hair_0008",
					"hair_0009",
					"hair_0010",
					"hair_0011",
					"hair_0012",
					"hair_0013",
					"hair_0014",
					"hair_0015",
					"hair_0016",
					"hair_0017",
					"hair_0018",
					"hair_0019",
					"hair_0020",
					"hair_0021",
					"hair_0022",
					"hair_0023",
					"hair_0024",
					"hair_0025",
					"hair_0026",
					"hair_0027",
					"hair_0028",
					"hair_0029",
					"hair_0030",
					"hair_0031",
					"hair_0032",
					"hair_0033",

					"hat_0001",
					"hat_0002",
					"hat_0003",
					"hat_0004",
					"hat_0005",
					"hat_0006",
					"hat_0007",
					"hat_0008",
					"hat_0009",
					"hat_0010",
					"hat_0011",
					"hat_0012",
					"hat_0013",
					"hat_0014",
					"hat_0015",
					"hat_0016",
					"hat_0017",
					"hat_0018",
					"hat_0019",
					"hat_0020",
					"hat_0021",
					"hat_0022",
					"hat_0023",
					"hat_0024",
					"hat_0025",
					"hat_0026",
					"hat_0027",
				],
			},
		],
	},
	// deploy
	{
		headline: "optionHeadlineProps",
		itemKey: "prop",
		basePath: "prop/",
		catImg: "../images/composition/minifigures/menu/menuIcon_0004.png",
		components: [
			{
				key: "prop",
				rowsToShow: 4,
				options: [
					"prop_0001",
					"prop_0002",
					"prop_0003",
					"prop_0004",
					"prop_0005",
					"prop_0006",
					"prop_0007",
					"prop_0008",
					"prop_0009",
					"prop_0010",
					"prop_0011",
					"prop_0012",
					"prop_0013",
					"prop_0014",
					"prop_0015",
					"prop_0016",
					"prop_0017",
					"prop_0018",
					"prop_0019",
					"prop_0020",
					"prop_0021",
					"prop_0022",
					"prop_0023",
					"prop_0024",
					"prop_0025",
					"prop_0026",
					"prop_0027",
					"prop_0028",
					"prop_0029",
					"prop_0030",
					"prop_0031",
					"prop_0032",
					"prop_0033",
					"prop_0034",
					"prop_0035",
					"prop_0036",
					"prop_0037",
					"prop_0038",
					"prop_0039",
					"prop_0040",
					"prop_0041",
					"prop_0042",
					"prop_0043",
					"prop_0044",
				],
			},
		],
	},
	{
		headline: "optionHeadlineStickers",
		itemKey: "stickers",
		basePath: "sticker/",
		catImg: "../images/composition/minifigures/menu/menuIcon_0006.png",
		components: [
			{
				key: "stickers",
				rowsToShow: 4,
				options: [
					"sticker_0001",
					"sticker_0002",
					"sticker_0003",
					"sticker_0004",
					"sticker_0005",
					"sticker_0006",
					"sticker_0007",
					"sticker_0008",
					"sticker_0009",
					"sticker_0010",
					"sticker_0011",
					"sticker_0012",
					"sticker_0013",
					"sticker_0014",
					"sticker_0015",
					"sticker_0016",
					"sticker_0017",
					"sticker_0018",
					"sticker_0019",
					"sticker_0020",
					"sticker_0021",
					"sticker_0022",
					"sticker_0023",
					"sticker_0024",
					"sticker_0025",
					"sticker_0026",
					"sticker_0027",
					"sticker_0028",
					"sticker_0029",
					"sticker_0030",
					"sticker_0031",
					"sticker_0032",
					"sticker_0033",
					"sticker_0034",
					"sticker_0035",
					"sticker_0036",
					"sticker_0037",
					"sticker_0038",
					"sticker_0039",
					"sticker_0040",
					"sticker_0041",
					"sticker_0042",
					"sticker_0043",
					"sticker_0044",
				],
			},
		],
	},
	{
		headline: "optionHeadlineMessage",
		itemKey: MESSAGE,
		basePath: "message/",
		catImg: "../images/composition/minifigures/menu/menuIcon_0005.png",
		components: [
			{
				key: MESSAGE,
				rowsToShow: 3,
				options: [
					"message_0033",
					"message_0001",
					"message_0002",
					"message_0003",
					"message_0004",
					"message_0005",
					"message_0006",
					"message_0007",
					"message_0008",
					"message_0009",
					"message_0010",
					"message_0011",
					"message_0012",
					"message_0013",
					"message_0014",
					"message_0015",
					"message_0016",
					"message_0017",
					"message_0018",
					"message_0019",
					"message_0020",
					"message_0021",
					"message_0022",
					"message_0023",
					"message_0024",
					"message_0025",
					"message_0026",
					"message_0027",
					"message_0028",
					"message_0029",
					"message_0030",
					"message_0031",
					"message_0032",
					"message_0034",
					"message_0035",
					"message_0036",
					"message_0037",
					"message_0038",
					"message_0039",
					"message_0040",
					"message_0041",
					"message_0042",
					"message_0043",
					"message_0044",
					"message_0045",
					"message_0046",
					"message_0047",
					"message_0048",
					"message_0049",
					"message_0050",
					"message_0051",
					"message_0052",
					"message_0053",
					"message_0054",
					"message_0055",
					"message_0056",
					"message_0057",
					"message_0058",
					"message_0059",
					"message_0060",
					"message_0061",
					"message_0062",
					"message_0063",
					"message_0064",
				],
			},
		],
	},
	{
		headline: "optionHeadlineBackcover",
		itemKey: BACKCOVER,
		catImg: "../images/composition/minifigures/menu/menuIcon_0007.png",
		basePath: "backpage/",
		components: [
			{
				key: BACKCOVER,
				rowsToShow: 1,
				options: [
					"backpage_0001",
					"backpage_0002",
					"backpage_0003",
					"backpage_0004",
					"backpage_0005",
				],
			},
		],
	},
]
