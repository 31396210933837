import React from 'react';
import Slider from "react-slick";
import { BACKCOVER, MESSAGE, MAX_CHAR_NAME, MAX_CHAR_MSG, MAX_COUNT_LINEBREAKS } from '../config';
import { helper_getAllIndices } from '../helper';
import { connect } from 'react-redux';
import axios from 'axios';
import { getEnvConfig } from '../env';


class Options extends React.Component {
    
    env = null;

    // eslint-disable-next-line
    allowedChars = "^[\r\n a-zäöüßA-ZÄÖÜ0123456789ÁÁĄÂÃÄÅÆĀĆČÇĈĐÐĎÈÉÊĚĖĘËĒĜĢĤÌÍĮÎÏĪĲĴĶĽĽĻŁŃŇŅÑÒÓÔÕÖŐØŌŒŔŜŚŠÙÚŰÛÜŪŮŲŤÝŸİIŹŻŽááąâãäåæāćčçĉđðďèéêěėęëēĝģĥìíįîïīĳĵķĽĽļłńňņñòóôõöőøōœŕŝśŠùúűûüūůųťýÿiıźżž(?/&+´`'!)¿¡.:,$€£¢¥*#§@=<>{}^~«»\„“\\[\\]-]*";
     // - has to be at the end of the regex
     // \r\n add, but will be removed afterwards
     
    state= {
        invalidChar: null,
        tmp_messageName: "",
        tmp_backcoverName: "",
        show_messageName_error: false,
        show_backcoverName_error: false,
        show_messageName_warning: false,
        show_backcoverName_warning: false,
    }

    componentDidMount = () => {
        this.env = getEnvConfig();
    }


    updateInvalidCharMsg = text => {

        const textTrimed = text.trim();

        if (textTrimed && !!textTrimed.length){
            const regex = new RegExp(this.allowedChars, "g");
            const match = textTrimed.match(regex);

            if (match && match[0] !== textTrimed ){
                this.setState({ 
                    invalidChar: textTrimed.substr(match[0].length, 1),
                 });
            }
        }
    }


    submitText = (e, textKey) => {

        e.preventDefault();

        const textTrimed = (this.state["tmp_"+textKey] || "").trim(); //.replace(/\r?\n|\r/g, " ");

        if (textTrimed && !!textTrimed.length){
            const regex = new RegExp(this.allowedChars, "g");
            
            const match = textTrimed.match(regex);
            

            if (match[0] === textTrimed ){

                this.setState({ 
                    isLoading: true,
                    show_messageName_error: false,
                    show_backcoverName_error: false,
                    show_messageName_warning: false,
                    show_backcoverName_warning: false,
                    invalidChar: null,
                })

                const data = {
                    title: textTrimed,
                }

                if (window.location.host === "localhost:3000"){
                    this.props.clickHandler(textKey, textTrimed, textKey);
                    this.props.setUnsavedChanges(false);
                } else {

                    axios.post(this.env.apibase + 'titleCheck.php?cv='+Date.now(), { data })
                        .then(res => {

                            if (res.status === 200){
                                // if (res.data.status === "ALLOWED"){
                                //     this.props.clickHandler(textKey, textTrimed);
                                // } else if ( res.data.status === "UNKNOWN"){
                                //     this.props.clickHandler(textKey, textTrimed);
                                //     this.setState({ ["show_"+textKey+"_warning"]: true, });
                                if ( res.data.status === "BLOCKED"){
                                    this.setState({ ["show_"+textKey+"_error"]: true, });
                                    this.props.clickHandler(textKey, null, textKey); // reset content in case ther was already one filled
                                } else {
                                    this.props.clickHandler(textKey, textTrimed, textKey);
                                    this.props.setUnsavedChanges(false);
                                }
                            }else {
                                this.setState({ ["show_"+textKey+"_error"]: true, });
                            }
                        })
                        .catch( (error) => {
                            this.setState({ ["show_"+textKey+"_error"]: true, });
                        })

                }
                
            } else {
                this.setState({ 
                    ["show_"+textKey+"_error"]: true,
                    invalidChar: textTrimed.substr(match[0].length, 1),
                 });
            }
        } else {
            // reset possibilty for text input
            this.props.clickHandler(textKey, null, textKey)
            this.props.setUnsavedChanges(false);
        }
    }


    splitOptionsIntoRows = (selectedKey, catItem, basePath, clickHandler) => {

        let optionGroup = [];
        let viewportWith = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let itemPerLine = 4;

        if(catItem.key === "color"){
            itemPerLine = 6;    // color should have always 6 items
        } else {
            if(catItem.key === MESSAGE){
                itemPerLine = 2;    // messages should have 2 items
            } else if(viewportWith < 641){
                itemPerLine = 5;    // all other items shoudl have 5 items on mobile  and 4 on desktop
            }
        }


        
        let i,j,chunk = itemPerLine * catItem.rowsToShow;
        for ( i = 0 , j = catItem.options.length; i < j ; i += chunk) {

            let chunkArr = catItem.options.slice(i,i+chunk);

            optionGroup.push(
                <div key={ "group"+i+j}>
                    <div className="Grid Grid--withGutter">
                        { chunkArr.map((item, index) => {

                            let fileNamePart =  item ;
                            
                            // color dependen thumbs handling
                            if(catItem.combinedSubKeyMapping){
                                
                                // skip combined key mapping for hats
                                if( fileNamePart.indexOf("hat_0") < 0){

                                    var genSubMappingKey = catItem.combinedSubKeyMapping;
                                    var indiArr = helper_getAllIndices(catItem.combinedSubKeyMapping, "[");
                                    
                                    for (var i = 0; i < indiArr.length; i++){
                                        
                                        var subIndexStart = genSubMappingKey.indexOf("[");
                                        var subIndexEnd = genSubMappingKey.indexOf("]");
                                        var subStringKey = genSubMappingKey.substr(subIndexStart , subIndexEnd+1);
                                        var replaceValue = subStringKey !== "[DYN]" ? this.props.composition[ subStringKey.substr(1, subStringKey.length-2) ] : item;
                                        
                                        genSubMappingKey = genSubMappingKey.replace(subStringKey, replaceValue);
                                    }
                                    fileNamePart = genSubMappingKey;
                                }
                            }

                            let completeThumbPath = "../images/composition/" + this.props.configurator.themeName + "/" + basePath  + fileNamePart + "_tmb.png" ;
                            if (catItem.key === MESSAGE || catItem.key === BACKCOVER){
                                completeThumbPath = "../images/composition/" + this.props.configurator.themeName + "/" + basePath  + this.props.locale + "_" + fileNamePart + "_tmb.png" ;
                            }
                            return (
                                <picture key={ item+index } className={ "u-size1of" + itemPerLine + (selectedKey === item ? " is-active ": "") }>
                                    <div className={ catItem.borderRadius ? catItem.key +" img is-rounded" : catItem.key +" img"  } style={{ backgroundImage: "url("+completeThumbPath+")" }} onClick={ () => clickHandler(item) } ></div>
                                    <img decoding="async" width="0" height="0" src={ completeThumbPath }  alt="groupoption filecheck" style={{ position: "absolute", width: 0, height: 0 , opacity: 0 }} onError={()=>{ console.error(completeThumbPath + " not found") }} />
                                    {/* ANDOIRD 7 BUG ... <img decoding="async" width="100" height="100" src={ completeThumbPath } className={ catItem.borderRadius ? "is-rounded" : "" } alt="groupoption" onClick={ () => clickHandler(item) } onError={()=>{ console.error(completeThumbPath + " not found") }} /> */}
                                </picture>
                            );
                        })}
                    </div>
                </div>
            ) ;
        }

        return optionGroup;


    }


    onChangeHandler = (key, value) => {
        this.updateInvalidCharMsg(value); 
        this.setState({ ["tmp_"+key]: value });

        // check unsafed changes state
        if(value !== this.props.composition[key]){
            this.props.setUnsavedChanges(true);
        }
    }

    render = () => {


        const settingSliderCats = {
            arrows: true,
        }


        if (this.props.item.itemKey === MESSAGE){

            return (
                <div className="slideroptions" >  
                    <h3 className="u-textCenter u-marginBottomMd">{ this.props.t.t(this.props.item.headline) }</h3>
                    <form onSubmit={ (e) => this.submitText(e, "messageName")  } className="slideroption">
                        <div className="formElement-charleft">{ this.state.tmp_messageName ? this.state.tmp_messageName.length : 0 }/{ MAX_CHAR_NAME }</div>
                        <label className={ "formElement Grid Grid--withGutter" + (this.state.show_messageName_error ? " formElement-hasError": "") }>
                            <input type="text" onChange={ (e) => { this.onChangeHandler("messageName", e.target.value) }} placeholder={ this.props.t.t("messagePlaceholder") } className="formElement-input u-size2of3"  maxLength={ MAX_CHAR_NAME } pattern={ this.allowedChars } title={ this.props.t.t("invalidChars") + " (" +this.state.invalidChar+ ")"} />
                            <button className="Button Button--default u-size1of3" type="submit" /* disabled={???? ? "disabled" : null } */ > 
                                { this.props.t.t("setInput") }
                            </button>
                            <div className="formElement-errmsg">{ this.props.t.t("optionInvalidName") }</div>
                            { this.state.show_messageName_warning && 
                                <div className="formElement-warnmsg">{ this.props.t.t("optionUnkownName") }</div>
                            }
                        </label>
                    </form>
                    { this.props.item.components.map((catItem, index) => 
                        <Slider key={ catItem.key + index } { ...settingSliderCats } className="slideroption">
                            { this.splitOptionsIntoRows(this.props.composition[catItem.key], catItem, this.props.item.basePath,  (value) => this.props.clickHandler(catItem.key, value, this.props.item.itemKey)) }
                        </Slider>
                    )}
                </div>
            );

        } else if (this.props.item.itemKey === BACKCOVER){
            
            return (
                <div className="slideroptions slideroptions-text">  
                    
                    <h3 className="u-textCenter">{ this.props.t.t("optionHeadlineBackCover") }</h3>

                    <form onSubmit={ (e) => this.submitText(e, "backcoverName")  } className="slideroption">
                        <div className="formElement-charleft">{ this.state.tmp_backcoverName ? this.state.tmp_backcoverName.length : 0 }/{ MAX_CHAR_MSG }</div>
                        <label className={ "formElement u-size1of1 " + (this.state.show_backcoverName_error ? " formElement-hasError": "") }>
                            <textarea onChange={ (e) => { this.onChangeHandler("backcoverName", e.target.value) }} placeholder={ this.props.t.t("backpagePlaceholder") } className="formElement-textarea" maxLength={ MAX_CHAR_MSG } pattern={ this.allowedChars } />
                            {/* fake input for pattern validation message   */}
                            <input style={{ opacity: 0, width: "100%", height: 0 }} onChange={()=>{}} value={ this.state.tmp_backcoverName } maxLength={ MAX_CHAR_MSG } pattern={ this.allowedChars } title={ this.props.t.t("invalidChars") + " (" +this.state.invalidChar+ ")" }/>
                            <div className="formElement-errmsg">{ this.props.t.t("optionInvalidBackpageCopy") }</div>
                            {
                                // show warning if there are more than 3 linebreaks
                                helper_getAllIndices(this.state.tmp_backcoverName, "\n").length >= MAX_COUNT_LINEBREAKS && 
                                <p>{ this.props.t.t("maxCountLineBreaks") }</p>
                            }
                            <div className="u-textCenter u-marginTopMd">
                                <button className="Button Button--default" type="submit"  > 
                                    { this.props.t.t("setInput") }
                                </button>
                            </div>
                        </label>
                    </form>
                   
                    { this.props.item.components[0].options.length > 1 && this.props.item.components.map((catItem, index) => 
                        <Slider key={ catItem.key + index } { ...settingSliderCats } className="slideroption">
                            { this.splitOptionsIntoRows(this.props.composition[catItem.key], catItem, this.props.item.basePath,  (value) => this.props.clickHandler(catItem.key, value, this.props.item.itemKey)) }
                        </Slider>
                    )}
                    
                </div>
            );

        } else {

            return (
                <div className="slideroptions" >  
                    <h3 className="u-textCenter u-marginBottomMd">{ this.props.t.t(this.props.item.headline) }</h3>
                    { this.props.item.components.map((catItem, index) => 
                        <Slider key={ catItem.key + index } { ...settingSliderCats } className="slideroption">
                            { this.splitOptionsIntoRows(this.props.composition[catItem.key], catItem, this.props.item.basePath,  (value) => this.props.clickHandler(catItem.key, value, this.props.item.itemKey), this.props.locale) }
                        </Slider>
                    )}
                </div>
            );

        }

    }



}


const mapStateToProp = state => ({
    address: state.address,
    composition: state.composition,
    receiver: state.receiver,
    configurator: state.configurator,
    locale: state.locale,
    t: state.t,
});

const mapDispatchToProps = dispatch => {
    return {
        // updateAddress: (key, value) => dispatch({ type: "UPDATE_ADDRESS", key: key, value: value }),
        // updateReceiver: (receiver) => dispatch({ type: "UPDATE_RECEIVER", receiver: receiver }),
    };
}
export default connect(mapStateToProp, mapDispatchToProps)(Options);    
    
